import React from 'react';
import { useTheme } from '@chakra-ui/react';
const TwitterIcon = () => {
  const theme = useTheme();
  return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 64 64" data-sentry-element="svg" data-sentry-component="TwitterIcon" data-sentry-source-file="TwitterIcon.tsx">
      <linearGradient id="HOaxCdew_So_FZGl4pPQ6a_bG29Ckcdp6YP_gr1" x1="32" x2="32" y1="9" y2="55" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="TwitterIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="TwitterIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="TwitterIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#HOaxCdew_So_FZGl4pPQ6a_bG29Ckcdp6YP_gr1)" d="M49,55H15c-3.309,0-6-2.691-6-6V15c0-3.309,2.691-6,6-6h34c3.309,0,6,2.691,6,6v34	C55,52.309,52.309,55,49,55z M15,11c-2.206,0-4,1.794-4,4v34c0,2.206,1.794,4,4,4h34c2.206,0,4-1.794,4-4V15c0-2.206-1.794-4-4-4H15	z" data-sentry-element="path" data-sentry-source-file="TwitterIcon.tsx"></path>
      <linearGradient id="HOaxCdew_So_FZGl4pPQ6b_bG29Ckcdp6YP_gr2" x1="32" x2="32" y1="13" y2="51" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="TwitterIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="TwitterIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="TwitterIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#HOaxCdew_So_FZGl4pPQ6b_bG29Ckcdp6YP_gr2)" d="M26.978,22l14.108,20h-3.063L23.914,22H26.978z M51,15v34c0,1.1-0.9,2-2,2H15	c-1.1,0-2-0.9-2-2V15c0-1.1,0.9-2,2-2h34C50.1,13,51,13.9,51,15z M44.914,44L34.789,29.613L43,20h-2.5l-6.841,8.009L28.022,20	h-7.937l9.222,13.103L20,44h2.5l7.937-9.292L36.978,44H44.914z" data-sentry-element="path" data-sentry-source-file="TwitterIcon.tsx"></path>
    </svg>;
};
export default TwitterIcon;