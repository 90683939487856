import React from 'react';
import { useTheme } from '@chakra-ui/react';
const RedditIcon = () => {
  const theme = useTheme();
  return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 64 64" data-sentry-element="svg" data-sentry-component="RedditIcon" data-sentry-source-file="RedditIcon.tsx">
      <linearGradient id="pgAleSNCvHJJ8c2smCaG1a_d2BoV6h39VrC_gr1" x1="32" x2="32" y1="8.787" y2="55.119" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="RedditIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#pgAleSNCvHJJ8c2smCaG1a_d2BoV6h39VrC_gr1)" d="M60,32.5c0-4.136-3.364-7.5-7.5-7.5c-1.782,0-3.463,0.616-4.811,1.75 c-4.242-2.319-9.5-3.621-14.96-3.729l3.03-12.877c0.124-0.528,0.653-0.863,1.186-0.748l11.843,2.581l0.426-1.954L37.37,7.442 c-1.588-0.347-3.187,0.66-3.559,2.244l-3.142,13.352c-5.244,0.189-10.273,1.478-14.358,3.711C14.963,25.616,13.282,25,11.5,25 C7.364,25,4,28.364,4,32.5c0,2.393,1.17,4.657,3.093,6.06C7.031,39.05,7,39.532,7,40c0,9.374,11.215,17,25,17s25-7.626,25-17 c0-0.468-0.031-0.95-0.093-1.44C58.83,37.157,60,34.893,60,32.5z M55.31,37.221c-0.358,0.213-0.546,0.626-0.472,1.036 C54.947,38.864,55,39.435,55,40c0,8.271-10.318,15-23,15S9,48.271,9,40c0-0.565,0.053-1.136,0.163-1.743 c0.074-0.41-0.114-0.823-0.472-1.036C7.006,36.215,6,34.451,6,32.5c0-3.033,2.467-5.5,5.5-5.5c1.499,0,2.901,0.596,3.948,1.677 c0.32,0.33,0.826,0.4,1.224,0.167C20.902,26.365,26.346,25,32,25s11.098,1.365,15.328,3.844c0.398,0.234,0.903,0.164,1.224-0.167 C49.599,27.596,51.001,27,52.5,27c3.033,0,5.5,2.467,5.5,5.5C58,34.451,56.994,36.215,55.31,37.221z" data-sentry-element="path" data-sentry-source-file="RedditIcon.tsx"></path>
      <linearGradient id="pgAleSNCvHJJ8c2smCaG1b_d2BoV6h39VrC_gr2" x1="49.5" x2="49.5" y1="6.83" y2="15.262" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="RedditIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
      </linearGradient>
      <circle cx="49.5" cy="11.5" r="4.5" fill="url(#pgAleSNCvHJJ8c2smCaG1b_d2BoV6h39VrC_gr2)" data-sentry-element="circle" data-sentry-source-file="RedditIcon.tsx"></circle>
      <linearGradient id="pgAleSNCvHJJ8c2smCaG1c_d2BoV6h39VrC_gr3" x1="22" x2="22" y1="33.106" y2="40.192" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="RedditIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
      </linearGradient>
      <circle cx="22" cy="37" r="4" fill="url(#pgAleSNCvHJJ8c2smCaG1c_d2BoV6h39VrC_gr3)" data-sentry-element="circle" data-sentry-source-file="RedditIcon.tsx"></circle>
      <linearGradient id="pgAleSNCvHJJ8c2smCaG1d_d2BoV6h39VrC_gr4" x1="42" x2="42" y1="33.106" y2="40.192" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="RedditIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
      </linearGradient>
      <circle cx="42" cy="37" r="4" fill="url(#pgAleSNCvHJJ8c2smCaG1d_d2BoV6h39VrC_gr4)" data-sentry-element="circle" data-sentry-source-file="RedditIcon.tsx"></circle>
      <linearGradient id="pgAleSNCvHJJ8c2smCaG1e_d2BoV6h39VrC_gr5" x1="32.081" x2="32.081" y1="33.106" y2="40.192" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="RedditIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="RedditIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#pgAleSNCvHJJ8c2smCaG1e_d2BoV6h39VrC_gr5)" d="M32,50c-3.604,0-7.03-1.031-9.647-2.902l-1.163,1.627C24.144,50.837,27.982,52,32,52 c4.1,0,7.996-1.205,10.972-3.393l-1.185-1.611C39.152,48.933,35.676,50,32,50z" data-sentry-element="path" data-sentry-source-file="RedditIcon.tsx"></path>
    </svg>;
};
export default RedditIcon;