'use client';

import { Flex, Grid, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Dashboard from '~/app/components/icons/Sticker/Dashboard';
import NinjaIcon from '~/app/components/icons/Sticker/NinjaIcon';
import StakingIcon from '~/app/components/icons/Sticker/StakingIcon';
import RabbitIcon from '~/app/components/icons/Sticker/RabbitIcon';
import SimpleCard from './SimpleCard';
import BullIcon from '~/app/components/icons/Sticker/BullIcon';
import InterfaceIcon from '~/app/components/icons/Sticker/InterfaceIcon';
import SectionTitleText from '~/app/home/components/SectionTitleText';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
export default function CardGrid() {
  const [flippedIndex, setFlippedIndex] = useState(null);
  const handleCardClick = index => {
    setFlippedIndex(index === flippedIndex ? null : index);
  };
  const networkConfig = useNetworkConfig();

  // the above ensures that if you click a card and one is already flipped, it will revert.
  // disable if we want users to have multiple cards flipped at once.

  return <Flex direction="column" justify="center" align="center" w="100%" data-sentry-element="Flex" data-sentry-component="CardGrid" data-sentry-source-file="index.tsx">
      <Flex justify="flex-end" w="100%" data-aos-id-simple data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <SectionTitleText data-aos-delay="200" data-aos-mirror="true" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-offset="100" aosAnchor="[data-aos-id-simple]" data-sentry-element="SectionTitleText" data-sentry-source-file="index.tsx">
          {networkConfig.appName} keeps it simple.
        </SectionTitleText>
      </Flex>
      <Flex w="100%" justify={{
      base: 'center',
      lg: 'flex-start'
    }} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Grid templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)'
      }} gap={{
        base: 2,
        lg: 4
      }} maxW={{
        md: '2xl',
        lg: '50%'
      }} data-aos-id-blocks data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {[{
          type: 'light',
          delay: '100',
          frontIcon: <InterfaceIcon />,
          frontText: 'Easy Interface.',
          backText: `${networkConfig.appName} is for everyone. We built our application with user experience as the top priority. 
              Whether you are new to DeFi or a savvy, grizzled veteran of bull and bear markets past, 
              you will find the ${networkConfig.appName} experience to be easy, simple and clean.`
        }, {
          type: 'dark',
          delay: '200',
          frontIcon: <RabbitIcon />,
          frontText: 'Instant Swaps',
          backText: `We route trades on ${networkConfig.appName} utilizing Odos' Smart Order Router, allowing you
            
            to access the deepest liquidity 
            pools and lowest fees. No gimmicks or hidden tricks—just a straightforward approach to ensure you get the best return for your assets every time.`
        }, {
          type: 'light',
          delay: '200',
          frontIcon: <NinjaIcon />,
          frontText: 'Concentrated Liquidity',
          backText: `If you've ever read whitepapers on V3 liquidity, you know it can get a bit...heady. But we have sought to simplify and demystify 
            that process for our users. We make V3 liquidity easy and accessible, whether you are utilizing manual ranges or automated offerings with A51. Here, you can get in 
            high-volume pools and start earning fees and ${networkConfig.appName} rewards with a few clicks of a button.`
        }, {
          type: 'dark',
          delay: '200',
          frontIcon: <StakingIcon />,
          frontText: 'Staking',
          backText: `${networkConfig.appName} offers convenient and lucrative single-asset staking for holders of ${networkConfig.appName} tokens. With our single-asset 
            staking, you can effortlessly put your tokens to work and start earning real-yield. Enjoy passive income without the hassle, 
            all while supporting the DEX and boosting your returns. 
            `
        }, {
          type: 'light',
          delay: '200',
          frontIcon: <BullIcon />,
          frontText: 'Automated Liquidity',
          backText: `Our mission to make V3 liquidity accessible led us to partner with A51 Finance. 
            A51's automated strategies allow you to set custom ranges for your liquidity, and it will automatically rebalance to keep you competitive. 
            A51 also lets you choose a market direction, providing dynamic adjustments as the market moves.`
        }, {
          type: 'dark',
          delay: '200',
          frontIcon: <Dashboard />,
          frontText: 'Dashboard',
          backText: `Our dashboard is your all-in-one hub for managing your assets. Easily view your wallet balances, monitor V2 and V3 liquidity, check your staked assets, 
            and track your deposited pools—all from one place. Stay on top of your DeFi game with a clear, consolidated view of your portfolio onchain.`
        }].map((card, index) => <SimpleCard key={index} type={card.type} delay={card.delay} frontContent={<Flex p="2" h="190px" w="350px" direction="column" justify="center" align="center">
                  {card.frontIcon}
                  <Text fontSize="24px" fontWeight="500" mb={2} userSelect="none">
                    {card.frontText}
                  </Text>
                </Flex>} backContent={<Flex p="2" h="190px" w="350px" direction="column" justify="center" align="center">
                  <Text fontSize="14px" userSelect="none" textAlign="left">
                    {card.backText}
                  </Text>
                </Flex>} isFlipped={flippedIndex === index} onClick={() => handleCardClick(index)} />)}
        </Grid>
      </Flex>
    </Flex>;
}