import React from 'react';
const MetaMask = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{
  width: '100%',
  height: '100%'
}} viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="MetaMask" data-sentry-source-file="Metamask.tsx">
    <path d="M45.568,93.969c-1.399,0-2.734-0.439-3.861-1.271l-6.351-4.699l-14.505,4.279	c-0.602,0.177-1.219,0.266-1.839,0.266c-2.854,0-5.347-1.829-6.202-4.551L7.104,69.878c-0.437-1.39-0.394-2.9,0.121-4.256	l5.279-14.009l-5.34-21.022c-0.317-1.245-0.259-2.547,0.17-3.763l4.605-12.993c0.58-1.636,1.816-2.994,3.393-3.726	c0.858-0.396,1.801-0.603,2.734-0.603c0.791,0,1.566,0.142,2.305,0.422L44.27,19h15.455l23.77-9.064	c0.745-0.283,1.522-0.426,2.315-0.426c0.939,0,1.849,0.198,2.701,0.588c1.572,0.719,2.813,2.062,3.405,3.685l4.711,12.928	c0.45,1.227,0.518,2.553,0.193,3.827l-5.335,21.097l5.198,14.045c0.504,1.366,0.534,2.875,0.088,4.255l-5.757,17.899	c-0.867,2.697-3.354,4.51-6.188,4.51c-0.609,0-1.216-0.086-1.802-0.255l-14.343-4.11l-6.343,4.709	c-1.11,0.826-2.487,1.282-3.875,1.282H45.568z" opacity=".35" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#f2f2f2" d="M43.568,91.969c-1.399,0-2.734-0.439-3.861-1.271l-6.351-4.699l-14.505,4.279	c-0.602,0.177-1.219,0.266-1.839,0.266c-2.854,0-5.347-1.829-6.202-4.551L5.104,67.878c-0.437-1.39-0.394-2.9,0.121-4.256	l5.279-14.009l-5.34-21.022c-0.317-1.245-0.259-2.547,0.17-3.763L9.94,11.835c0.58-1.636,1.816-2.994,3.393-3.726	c0.858-0.396,1.801-0.603,2.734-0.603c0.791,0,1.566,0.142,2.305,0.422L42.27,17h15.455l23.77-9.064	c0.745-0.283,1.522-0.426,2.315-0.426c0.939,0,1.849,0.198,2.701,0.588c1.572,0.719,2.813,2.062,3.405,3.685l4.711,12.928	c0.45,1.227,0.518,2.553,0.193,3.827l-5.335,21.097l5.198,14.045c0.504,1.366,0.534,2.875,0.088,4.255l-5.757,17.899	c-0.867,2.697-3.354,4.51-6.188,4.51c-0.609,0-1.216-0.086-1.802-0.255l-14.343-4.11l-6.343,4.709	c-1.11,0.826-2.487,1.282-3.875,1.282H43.568z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#f9b84f" d="M36,59v6.988l8.74,6.428l1.643-1.127L44.5,62L36,59z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#f9b84f" d="M34.027,45.671l-16.152,4.41l-5.988,15.888L36,65.988L30,52L34.027,45.671z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M15.75,15.25l29.717,22.283l-4.416-13.908L15.75,15.25z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M11.875,65.988L17.5,83.375l18-5.5l-8.343-11.887H11.875z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M34.027,45.671L29.912,52H46l-0.818-16.203L34.027,45.671z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M35,77l9.74-4.584L36,65.988L35,77z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#d9eeff" d="M35,77l9.198,4.935l0.592-9.56L35,77z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#e66f2e" d="M35,77.375l1-11.387h-8.92L35,77.375z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M29.947,52L36,65.988V59L29.947,52z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#9ba7c5" d="M50.02,78.013L44,78v3.125L35,77l-0.875,0.25L44.062,85l5.958,0.084V78.013z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#e66f2e" d="M12.375,27.125l5.5,23.25l16.153-4.704l10.726-9.048L16.5,15.625L12.375,27.125z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#e66f2e" d="M36,59l8.5,3l1.423-10H29.912L36,59z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M59.5,23.75l-4.967,13.783l29.342-22.408L59.5,23.75z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M72.734,65.969l-8.359,12.156L82.5,83L88,65.969H72.734z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M54.818,35.797L54,52h17l-5.027-6.329L54.818,35.797z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M63.984,66l-8.724,6.416L65.125,77L63.984,66z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#e66f2e" d="M72.875,65.969L63.984,66l1.141,11L72.875,65.969z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#d9eeff" d="M55.312,72.375l0.592,9.56L65.125,77L55.312,72.375z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#f9b84f" d="M55.5,62l-1.945,9.254l1.706,1.163L63.984,66l0.012-6.98L55.5,62z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#9ba7c5" d="M50.02,85.084L55.969,85l10.156-7.75l-1-0.25L56,81l0-3l-5.98,0.013V85.084z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#e66f2e" d="M83.875,15.125L55.246,36.622l10.726,9.048l15.903,4.579L87.625,27L83.875,15.125z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#f9b84f" d="M70.125,52L63.5,66l24.504-0.031l-5.879-15.888l-16.152-4.41L70.125,52z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <polygon fill="#f9b84f" points="50.27,23.625 49.25,23.625 40.375,23.625 44.754,36.622 45.916,52 46.095,58.532 46.123,71.479 49,71.479 49.998,71.479 53.877,71.479 53.905,58.532 54.1,52 55.246,36.622 59.5,23.625" data-sentry-element="polygon" data-sentry-source-file="Metamask.tsx"></polygon>
    <polygon fill="#40396e" points="44.74,72.416 44,78 50.02,78.013 50.02,70.984 46.166,70.984" data-sentry-element="polygon" data-sentry-source-file="Metamask.tsx"></polygon>
    <polygon fill="#40396e" points="55.26,72.416 53.834,70.984 50.02,70.984 50.02,78.013 56,78" data-sentry-element="polygon" data-sentry-source-file="Metamask.tsx"></polygon>
    <path fill="#e66f2e" d="M70.109,52H54.077L55.5,62l8.496-2.98L70.109,52z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#ef8630" d="M63.996,59.02L63.984,66l6.078-14L63.996,59.02z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#f9b84f" d="M45.916,52L44.5,62l1.602,9.008l0.45-12.504L45.916,52z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#f9b84f" d="M53.448,58.503l0.423,12.469L55.5,62l-1.417-10L53.448,58.503z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#40396e" d="M44.5,62L36,59l5.816-2.403L44.5,62z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#40396e" d="M58.184,56.597l5.812,2.422L55.5,62L58.184,56.597z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#40396e" d="M11.875,26.234l5.75,24.141l16.403-4.704l10.726-9.048L15.813,15.125L11.875,26.234z" opacity=".35" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#40396e" d="M83.875,15.125L55.246,36.622l10.726,9.048L82,50l5.75-22.75L83.875,15.125z" opacity=".35" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
    <path fill="#40396e" d="M56.465,86.969H43.568c-0.329,0-0.649-0.108-0.911-0.309l-8.33-6.374l-16.874,5.19	c-0.796,0.242-1.625-0.198-1.872-0.983L9.873,66.375c-0.101-0.32-0.092-0.666,0.027-0.979l5.838-15.491L10.011,27.36	c-0.073-0.288-0.059-0.59,0.04-0.87l4.603-12.984c0.135-0.38,0.418-0.69,0.784-0.86c0.367-0.169,0.786-0.184,1.162-0.041L41.353,22	h17.293l24.63-9.392c0.375-0.143,0.793-0.129,1.157,0.037c0.365,0.167,0.648,0.473,0.786,0.851l4.711,12.926	c0.104,0.283,0.119,0.59,0.045,0.881l-5.717,22.61l5.736,15.501c0.116,0.315,0.124,0.66,0.021,0.98l-5.76,17.908	c-0.25,0.777-1.076,1.211-1.857,0.978l-16.717-4.998l-8.302,6.376C57.116,86.86,56.795,86.969,56.465,86.969z M44.076,83.969h11.879	l8.506-6.533c0.382-0.294,0.879-0.387,1.344-0.248l16.044,4.796L87,65.969l-5.718-15.451c-0.104-0.284-0.121-0.594-0.047-0.888	l5.718-22.614l-4.031-11.062l-23.466,8.948C59.286,24.967,59.104,25,58.922,25H41.077c-0.182,0-0.362-0.033-0.532-0.098	l-23.576-8.949l-3.938,11.109l5.731,22.562c0.075,0.297,0.058,0.611-0.051,0.898l-5.821,15.447l5.104,16.203l16.188-4.98	c0.463-0.142,0.969-0.052,1.353,0.242L44.076,83.969z" data-sentry-element="path" data-sentry-source-file="Metamask.tsx"></path>
  </svg>;
export default MetaMask;