import React from 'react';
import { useTheme } from '@chakra-ui/react';
const MediumIcon = () => {
  const theme = useTheme();
  return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 64 64" data-sentry-element="svg" data-sentry-component="MediumIcon" data-sentry-source-file="MediumIcon.tsx">
      <linearGradient id="8hD4rQAUvDlnOcrtKRMHva_gU6bwZNC5TXf_gr1" x1="32" x2="32" y1="542.25" y2="590.76" gradientTransform="translate(0 -534)" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="MediumIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="MediumIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="MediumIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#8hD4rQAUvDlnOcrtKRMHva_gU6bwZNC5TXf_gr1)" d="M51,56H13c-2.757,0-5-2.243-5-5V13c0-2.757,2.243-5,5-5h38c2.757,0,5,2.243,5,5v38	C56,53.757,53.757,56,51,56z M13,10c-1.654,0-3,1.346-3,3v38c0,1.654,1.346,3,3,3h38c1.654,0,3-1.346,3-3V13c0-1.654-1.346-3-3-3H13	z" data-sentry-element="path" data-sentry-source-file="MediumIcon.tsx"></path>
      <linearGradient id="8hD4rQAUvDlnOcrtKRMHvb_gU6bwZNC5TXf_gr2" x1="32" x2="32" y1="19.092" y2="45.092" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="MediumIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="MediumIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="MediumIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#8hD4rQAUvDlnOcrtKRMHvb_gU6bwZNC5TXf_gr2)" d="M44.973,22.703l2.768-2.334c0.513-0.433,0.211-1.276-0.457-1.276h-8.022l-6.774,16.448	l-7.504-16.449h-5.71h-2.56c-0.636,0-0.954,0.778-0.505,1.233l3.065,3.101v2.166V40.76l-2.855,4.333h8.566l-2.855-4.334V27.036	l6.425,18.056h3.569l7.139-18.056v15.167l-2.855,2.889H47.83l-2.855-2.889v-19.5H44.973z" data-sentry-element="path" data-sentry-source-file="MediumIcon.tsx"></path>
    </svg>;
};
export default MediumIcon;