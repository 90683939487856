import React from 'react';
const Frax = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{
  width: '100%',
  height: '100%'
}} viewBox="0 0 283.46 283.46" data-sentry-element="svg" data-sentry-component="Frax" data-sentry-source-file="Frax.tsx">
    <g data-sentry-element="g" data-sentry-source-file="Frax.tsx">
      <g data-sentry-element="g" data-sentry-source-file="Frax.tsx">
        <path d="M141.73,283.46c-19.13,0-37.69-3.75-55.17-11.14c-16.88-7.14-32.03-17.36-45.05-30.37
			c-13.01-13.01-23.23-28.17-30.37-45.05C3.75,179.42,0,160.86,0,141.73s3.75-37.69,11.14-55.17c7.14-16.88,17.36-32.03,30.37-45.05
			C54.53,28.5,69.68,18.28,86.56,11.14C104.04,3.75,122.6,0,141.73,0s37.69,3.75,55.17,11.14c16.88,7.14,32.03,17.36,45.05,30.37
			c13.01,13.01,23.23,28.17,30.37,45.05c7.39,17.48,11.14,36.04,11.14,55.17s-3.75,37.69-11.14,55.17
			c-7.14,16.88-17.36,32.03-30.37,45.05c-13.01,13.01-28.17,23.23-45.05,30.37C179.42,279.72,160.86,283.46,141.73,283.46z" data-sentry-element="path" data-sentry-source-file="Frax.tsx" />
      </g>
      <g data-sentry-element="g" data-sentry-source-file="Frax.tsx">
        <circle cx="141.73" cy="141.73" r="134.22" data-sentry-element="circle" data-sentry-source-file="Frax.tsx" />
      </g>
      <path d="M212.43,141.73c0-14.38-4.32-27.76-11.72-38.94l21.95-21.95L202.92,61.1l-21.89,21.89
		c-11.24-7.55-24.76-11.96-39.29-11.96c-14.38,0-27.76,4.32-38.94,11.72L80.84,60.8L61.1,80.54l21.89,21.89
		c-7.55,11.24-11.96,24.76-11.96,39.29c0,14.38,4.32,27.76,11.72,38.94L60.8,202.62l19.74,19.74l21.89-21.89
		c11.24,7.55,24.76,11.96,39.29,11.96c14.38,0,27.76-4.32,38.94-11.72l21.96,21.96l19.74-19.74l-21.89-21.89
		C208.02,169.78,212.43,156.26,212.43,141.73z M98.96,141.73c0-23.59,19.19-42.78,42.78-42.78s42.78,19.19,42.78,42.78
		s-19.19,42.78-42.78,42.78S98.96,165.32,98.96,141.73z" fill="#fff" data-sentry-element="path" data-sentry-source-file="Frax.tsx" />
    </g>
  </svg>;
export default Frax;