'use client';

import { Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import SectionShell from '../../components/SectionShell';
import DefiMarquee from './DefiMarquee';
import SectionTitleText from '../../components/SectionTitleText';
export default function Section3Defi() {
  return <SectionShell data-sentry-element="SectionShell" data-sentry-component="Section3Defi" data-sentry-source-file="index.tsx">
      {' '}
      <Flex w="100%" justify="flex-start" align="center" direction="column" mb="0rem" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Flex w="100%" justify="flex-start" my="2" mb="3rem" data-aos-id-defititle data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <SectionTitleText aosAnchor="[data-aos-id-defititle]" data-sentry-element="SectionTitleText" data-sentry-source-file="index.tsx">
            {' '}
            DeFi has never been more intricate.
          </SectionTitleText>
        </Flex>
        <DefiMarquee data-sentry-element="DefiMarquee" data-sentry-source-file="index.tsx" />{' '}
      </Flex>
    </SectionShell>;
}