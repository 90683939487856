import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
export default function SectionShell({
  children
}: {
  children: ReactNode;
}) {
  return <Flex direction="column" justify="center" align="center" w="100%" bg="transparent" backdropFilter="blur(12px)" maxW="100%" overflowX="hidden" overflowY="hidden" px={{
    base: 1,
    sm: 6
  }} borderWidth={{
    base: '0px',
    lg: '0px'
  }} borderColor="darkborder" borderRadius={{
    base: '18px',
    lg: '18px'
  }} boxShadow="outsetComponentShadow" py={{
    base: 8
  }} data-aos="fade-up" data-aos-duration="500" data-aos-mirror="true" data-sentry-element="Flex" data-sentry-component="SectionShell" data-sentry-source-file="SectionShell.tsx">
      {children}
    </Flex>;
}