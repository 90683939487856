import React from 'react';
const Bitcoin = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{
  width: '100%',
  height: '100%'
}} viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="Bitcoin" data-sentry-source-file="Bitcoin.tsx">
    <circle cx="52" cy="52" r="45.585" opacity=".35" data-sentry-element="circle" data-sentry-source-file="Bitcoin.tsx"></circle>
    <circle cx="50" cy="50" r="45.585" fill="#f2f2f2" data-sentry-element="circle" data-sentry-source-file="Bitcoin.tsx"></circle>
    <path fill="#f9b84f" d="M88.667,50c0,21.352-17.315,38.667-38.667,38.667S11.333,71.352,11.333,50S28.648,11.333,50,11.333 S88.667,28.648,88.667,50z" data-sentry-element="path" data-sentry-source-file="Bitcoin.tsx"></path>
    <path fill="#40396e" d="M50,90.167C27.852,90.167,9.833,72.148,9.833,50S27.852,9.833,50,9.833S90.167,27.852,90.167,50 S72.148,90.167,50,90.167z M50,12.833c-20.494,0-37.167,16.673-37.167,37.167S29.506,87.167,50,87.167S87.167,70.494,87.167,50 S70.494,12.833,50,12.833z" data-sentry-element="path" data-sentry-source-file="Bitcoin.tsx"></path>
    <path fill="#f2f2f2" d="M36,69V30h15.988c4.7,0,8.256,0.907,10.686,2.72c2.43,1.814,3.641,4.471,3.641,7.968 c0,1.911-0.488,3.596-1.455,5.051c-0.975,1.455-2.33,2.521-4.068,3.2c1.981,0.503,3.541,1.507,4.684,3.032 c1.145,1.513,1.724,3.364,1.724,5.565c0,3.748-1.188,6.589-3.565,8.516S57.877,68.961,53.482,69H36z M44,46h8.726 c4.126-0.07,6.196-1.648,6.196-4.89c0-1.812-0.524-3.118-1.57-3.914S54.656,36,52.398,36H44V46z M44,52v12h9.688 C55.61,64,60,63.062,60,58.782S57.766,52.054,54,52H44z" data-sentry-element="path" data-sentry-source-file="Bitcoin.tsx"></path>
    <g data-sentry-element="g" data-sentry-source-file="Bitcoin.tsx">
      <path fill="#f2f2f2" d="M42,24h6v10h-6V24z M52,24h6v10h-6V24z M42,66h6v10h-6V66z M52,66h6v10h-6V66z" data-sentry-element="path" data-sentry-source-file="Bitcoin.tsx"></path>
    </g>
  </svg>;
export default Bitcoin;