'use client';

import React from 'react';
import { motion } from 'framer-motion';
const MotionCard = ({
  children,
  hover = 1.04,
  tap = 0.9,
  transitionDuration = 0.2
}) => {
  return <motion.div whileHover={{
    scale: hover
  }} whileTap={{
    scale: tap
  }} transition={{
    duration: transitionDuration
  }} data-sentry-element="unknown" data-sentry-component="MotionCard" data-sentry-source-file="MotionCard.tsx">
      {children}
    </motion.div>;
};
export default MotionCard;