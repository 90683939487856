import React from 'react';
const RabbitIcon = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="RabbitIcon" data-sentry-source-file="RabbitIcon.tsx">
    <circle cx="52" cy="52" r="44" opacity=".35" data-sentry-element="circle" data-sentry-source-file="RabbitIcon.tsx"></circle>
    <circle cx="50" cy="50" r="44" fill="#f2f2f2" data-sentry-element="circle" data-sentry-source-file="RabbitIcon.tsx"></circle>
    <path fill="#d9eeff" stroke="#40396e" strokeMiterlimit="10" strokeWidth="3" d="M87.5,50c0,20.7-16.8,37.5-37.5,37.5S12.5,70.7,12.5,50S29.3,12.5,50,12.5S87.5,29.3,87.5,50z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#b2ff59" d="M53,48.2c0.5,0.8,0.6,1.7,0.4,2.6c-0.1,0.3-0.2,0.6-0.4,0.9h-5.9c-0.4-0.8-0.5-1.7-0.3-2.5	c0.2-0.9,0.8-1.7,1.6-2.2c0.5-0.3,1.1-0.4,1.7-0.4C51.2,46.6,52.3,47.2,53,48.2z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#e66f2e" d="M54.2,38.7c0.6,0.2,1.3,0.5,1.9,0.9c1.9,1.1,3.4,2.7,4.4,4.5l15.4-11.2c-3.7-5.7-9.3-10-15.8-12.3L54.2,38.7z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#96c362" d="M18.9,50c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0.1,0.9h15.5c1.8,0,3.2-1.3,3.4-3.1c0.2-1.6,0.7-3.2,1.5-4.6	L24.1,32.9C20.8,37.8,18.9,43.7,18.9,50z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#739f4d" d="M24.1,32.9L39.4,44c0-0.1,0.1-0.1,0.1-0.2c1.5-2.5,3.7-4.3,6.3-5.2l-5.9-18.1C33.3,22.8,27.8,27.2,24.1,32.9z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#ef8630" d="M39.9,20.6l5.9,18.1c2.7-1,5.7-1,8.5,0l5.9-18.1c-3.2-1.1-6.6-1.7-10.1-1.7S43.1,19.5,39.9,20.6z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#b73b3b" d="M60.5,44.1c0.5,0.9,0.9,1.9,1.2,2.9c0.1,0.6,0.3,1.1,0.3,1.7c0.2,1.8,1.7,3.1,3.4,3.1H81	c0-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6,0-0.9c0-6.3-1.9-12.2-5.1-17.1L60.5,44.1z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#40396e" d="M51,54.2l17.5-6.8c0.9-0.4,1.3-1.4,1-2.3c-0.3-0.7-1-1.2-1.8-1.1L49,45.8c-2.4,0.2-4.2,2.4-3.9,4.8	c0.2,2.4,2.4,4.2,4.8,3.9C50.2,54.4,50.6,54.3,51,54.2z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#40396e" d="M47.4,45.5c-2.5,1.4-3.3,4.6-1.8,7.1c1.5,2.5,4.6,3.3,7.1,1.9c2.5-1.4,3.3-4.6,1.8-7.1	C53,44.9,49.8,44.1,47.4,45.5" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#d9eeff" d="M49.2,48.5c-0.8,0.5-1.1,1.5-0.6,2.4c0.5,0.8,1.6,1.1,2.4,0.6c0.8-0.5,1.1-1.5,0.6-2.4	C51.1,48.3,50,48,49.2,48.5" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#707cc0" d="M54.6,66.2c-1.4-1.3-2.5-2.6-3.8-3.3c-1.3-0.7-2.7-0.9-4.2-0.8c-1,0.1-1.8-0.6-1.9-1.6c-0.1-1,0.6-1.8,1.6-1.9	c0.1,0,0.2,0,0.3,0l0,0c2,0.1,4.1,0.8,5.7,2.1c1.6,1.3,2.6,3,3.6,4.2l0,0.1c0.3,0.4,0.2,0.9-0.1,1.2C55.4,66.5,54.9,66.4,54.6,66.2z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#40396e" d="M54.6,66.2c-1.4-1.3-2.5-2.6-3.8-3.3c-1.3-0.7-2.7-0.9-4.2-0.8c-1,0.1-1.8-0.6-1.9-1.6c-0.1-1,0.6-1.8,1.6-1.9	c0.1,0,0.2,0,0.3,0l0,0c2,0.1,4.1,0.8,5.7,2.1c1.6,1.3,2.6,3,3.6,4.2l0,0.1c0.3,0.4,0.2,0.9-0.1,1.2C55.4,66.5,54.9,66.4,54.6,66.2z" opacity=".35" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#707cc0" d="M52.8,66.2c-1.2-1.1-2.4-1.8-3.7-2.1c-1.3-0.3-2.8-0.3-4.3-0.2c-1,0.1-1.8-0.6-1.9-1.6c-0.1-1,0.6-1.8,1.6-1.9	c0.1,0,0.2,0,0.3,0c1.7,0.2,3.5,0.5,5.2,1.2c1.7,0.7,3.1,2.1,4.1,3.4l0,0c0.3,0.4,0.2,0.9-0.1,1.2C53.6,66.4,53.2,66.4,52.8,66.2z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#707cc0" d="M48.3,79.4l0-2l3.9-4.9l4.2-1.1c-0.2,2.8-1.1,4.5-2.8,6.2c-1.7,1.7-3.5,1.7-3.5,1.7H48.3z" opacity=".35" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#707cc0" d="M40.6,69.7c-0.1,0-0.2-0.1-0.4-0.1c-0.7-0.3-1.3-0.6-1.9-1.1c-0.4-0.4-0.8-0.8-1.1-1.3c-0.5,0.4-0.9,0.9-1,1.5	c-0.7,3.5,3,5.4,5,5.4c0,0,0,0,0.1,0C40.5,72.9,40.2,71.3,40.6,69.7z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#707cc0" d="M53.5,75.4c-2.7,2.1-6.5,2.9-9.7,1.7c-3.2-1.2-5-4.2-3.8-7.5c0.9-2.4,2.4-4.1,4.8-4.1c3.5,0,3.5,1.7,5.2,1.7	c0.5,0.2,2.4-0.2,2.6-0.8C56.6,66.3,59.5,68.6,53.5,75.4z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#707cc0" d="M60.1,68.2c-0.6,1.6-4.2,0.4-6-0.1s-2.8-2.3-2.2-3.9c0.6-1.6,2.6-2.5,4.4-2C58.1,62.8,61.2,65.3,60.1,68.2z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#40396e" d="M56,63.8c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9S56.5,63.8,56,63.8z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#9aa2e6" d="M48.4,78.8l1.6,2.3h3.5V80c0,0,0-2.3-2-2.3L48.4,78.8z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#9aa2e6" d="M46.4,69.3c4.3,2.4,3.5,6.8-1.5,8.3c0,0,1.1-1.1-2-2.3c-2.1-0.8-3.2-2.9-1.8-4.9C42.2,68.8,44.4,68.2,46.4,69.3	z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
    <path fill="#9aa2e6" d="M50,81.1c0.4-0.6,2.4-3.1,1.7-3.5L46.9,77c-0.6-0.4,1-4.8,1-4.8l-3.1,5.5L50,81.1z" data-sentry-element="path" data-sentry-source-file="RabbitIcon.tsx"></path>
  </svg>;
export default RabbitIcon;