import React from 'react';
const Orbs = () => <svg style={{
  width: '100%',
  height: '100%'
}} viewBox="0 0 1413 1370" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Orbs" data-sentry-source-file="Orbs.tsx">
    <path d="M405.732 876.313C401.727 891.455 388.71 895.118 377.195 884.372L148.646 676.296C136.88 665.55 139.383 652.118 154.403 646.256L475.073 521.215C490.093 515.354 498.854 523.169 494.849 538.31L405.732 876.313Z" fill="url(#paint0_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M216.985 1074.13C204.719 1084.39 192.453 1080.24 189.699 1064.61L127.117 726.361C124.364 710.975 131.623 707.067 143.389 717.813L360.924 915.877C372.689 926.622 372.439 943.718 360.173 953.975L216.985 1074.13Z" fill="url(#paint1_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M479.329 453.81C489.842 465.777 486.088 480.186 471.318 486.047L152.401 610.356C137.381 616.217 129.871 608.891 135.378 594.237L266.3 250.862C271.807 236.209 285.074 233.766 295.588 245.733L479.329 453.81Z" fill="url(#paint2_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M145.391 1016.5C148.145 1031.88 143.389 1033.84 134.627 1020.65L10.9653 834.307C2.20383 821.119 2.95481 800.116 12.4673 787.416L67.7897 715.127C77.3022 702.672 87.5656 704.869 90.5696 720.255L145.391 1016.5Z" fill="url(#paint3_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M106.34 573.234C100.833 587.887 92.0715 587.887 86.8147 572.99L44.5092 449.414C39.5027 434.516 45.0099 413.758 57.0256 403.256L207.723 270.155C219.739 259.654 224.745 263.073 219.238 277.726L106.34 573.234Z" fill="url(#paint4_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M66.5381 620.857C71.5446 635.755 68.04 658.223 58.2772 670.679L15.2209 727.338C5.70842 739.793 -0.800107 737.351 0.70186 721.721L21.4791 525.367C23.2314 509.736 28.7386 509.248 33.7451 524.146L66.5381 620.857Z" fill="url(#paint5_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M236.511 178.816C241.768 184.922 236.511 198.354 224.495 209.1L156.906 268.934C144.891 279.436 143.138 277.726 152.651 265.271L209.475 190.783C218.988 178.084 231.254 172.711 236.511 178.816Z" fill="url(#paint6_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M664.071 1223.6C656.06 1237.03 641.792 1237.76 632.78 1224.82L455.548 973.269C446.536 960.325 452.043 948.358 468.064 946.893L824.03 910.992C840.051 909.283 846.56 919.052 838.299 932.728L664.071 1223.6Z" fill="url(#paint7_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M589.974 1223.84C598.985 1236.78 593.979 1243.13 578.959 1238.25L245.272 1127.62C230.002 1122.49 227.499 1110.03 239.765 1099.78L382.702 979.619C394.968 969.362 412.241 971.559 421.503 984.503L589.974 1223.84Z" fill="url(#paint8_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M831.54 852.624C842.054 864.59 837.548 875.58 821.527 877.29L460.304 913.679C444.283 915.388 434.52 904.154 438.525 889.013L528.894 545.881C532.899 530.74 544.664 528.053 555.178 539.776L831.54 852.624Z" fill="url(#paint9_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1090.88 1146.67C1101.39 1158.63 1097.14 1170.84 1081.37 1174.26L713.886 1249.73C698.115 1252.9 691.857 1244.6 700.118 1230.92L873.345 941.764C881.355 928.332 896.625 926.867 907.139 938.834L1090.88 1146.67Z" fill="url(#paint10_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M499.856 1339.6C485.587 1346.93 464.309 1344.49 452.293 1334.23L281.57 1187.21C269.554 1176.71 272.057 1172.55 287.327 1177.44L579.209 1274.15C594.479 1279.28 595.231 1289.29 581.212 1296.86L499.856 1339.6Z" fill="url(#paint11_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M896.124 1353.52C883.608 1363.53 861.83 1365.73 847.311 1358.65L728.405 1299.55C714.136 1292.47 715.137 1283.92 730.908 1280.74L1047.57 1215.78C1063.34 1212.61 1066.1 1217.98 1053.58 1227.99L896.124 1353.52Z" fill="url(#paint12_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M564.69 1360.6C548.669 1359.87 547.168 1353.28 561.186 1345.71L625.019 1312.25C639.288 1304.92 662.569 1304.68 676.837 1311.76L769.959 1357.92C784.228 1365 782.976 1370.37 766.705 1369.64L564.69 1360.6Z" fill="url(#paint13_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1150.21 1213.58C1155.46 1219.69 1148.45 1230.68 1134.19 1238.01L1050.33 1281.72C1036.06 1289.05 1034.81 1287.09 1047.07 1277.08L1117.91 1220.67C1130.18 1210.65 1144.7 1207.48 1150.21 1213.58Z" fill="url(#paint14_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M937.929 848.96C922.909 854.821 914.148 847.006 918.153 831.865L1007.27 493.862C1011.28 478.72 1024.29 475.057 1036.06 485.803L1264.61 693.879C1276.37 704.625 1273.87 718.057 1258.85 723.919L937.929 848.96Z" fill="url(#paint15_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1286.14 643.814C1288.89 659.2 1281.63 663.108 1269.86 652.362L1052.33 454.298C1040.56 443.552 1040.81 426.457 1053.08 416.2L1196.02 296.043C1208.28 285.785 1220.55 289.937 1223.3 305.567L1286.14 643.814Z" fill="url(#paint16_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1146.7 1119.31C1141.2 1133.97 1127.93 1136.41 1117.41 1124.44L933.674 916.365C923.16 904.398 926.915 889.989 941.684 884.128L1260.6 759.819C1275.62 753.958 1283.13 761.285 1277.62 775.938L1146.7 1119.31Z" fill="url(#paint17_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1402.04 535.868C1410.8 549.056 1410.05 570.059 1400.54 582.759L1345.21 654.804C1335.7 667.259 1325.44 665.061 1322.43 649.676L1267.61 353.679C1264.86 338.293 1269.61 336.339 1278.38 349.527L1402.04 535.868Z" fill="url(#paint18_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1368.24 921.005C1373.25 935.903 1367.74 956.662 1355.73 967.163L1205.03 1100.26C1193.01 1110.77 1188.01 1107.35 1193.51 1092.69L1306.41 796.941C1311.92 782.288 1320.68 782.288 1325.94 797.185L1368.24 921.005Z" fill="url(#paint19_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1391.77 844.564C1390.02 860.194 1384.51 860.683 1379.51 845.785L1346.46 749.074C1341.46 734.176 1345.21 711.708 1354.72 699.252L1398.03 642.593C1407.54 630.138 1414.05 632.58 1412.55 648.21L1391.77 844.564Z" fill="url(#paint20_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1203.53 1179.39C1194.01 1192.09 1181.75 1197.22 1176.24 1191.36C1170.98 1185.25 1176.24 1171.82 1188.26 1161.08L1256.1 1101.24C1268.11 1090.74 1269.86 1092.45 1260.35 1104.9L1203.53 1179.39Z" fill="url(#paint21_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M957.455 396.906C966.467 409.85 960.959 421.817 944.938 423.282L588.972 459.183C572.951 460.892 566.443 451.123 574.703 437.447L748.932 146.579C756.942 133.147 771.211 132.414 780.223 145.358L957.455 396.906Z" fill="url(#paint22_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1030.3 390.557C1018.03 400.814 1000.76 398.616 991.499 385.672L823.029 146.335C814.017 133.391 819.023 127.041 834.043 131.926L1167.73 242.558C1183 247.687 1185.5 260.142 1173.24 270.4L1030.3 390.557Z" fill="url(#paint23_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M884.109 824.294C880.103 839.436 868.338 842.122 857.824 830.399L581.713 517.552C571.199 505.585 575.705 494.595 591.726 492.885L952.949 456.496C968.97 454.787 978.733 466.021 974.727 481.163L884.109 824.294Z" fill="url(#paint24_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M539.658 428.655C531.647 442.087 516.377 443.553 505.863 431.586L322.123 223.509C311.609 211.542 315.865 199.331 331.635 195.912L699.116 120.448C714.887 117.273 721.145 125.576 712.885 139.253L539.658 428.655Z" fill="url(#paint25_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M833.793 96.0254C818.523 90.8967 817.772 80.8836 831.79 73.3128L913.147 30.8182C927.415 23.4916 948.693 25.9338 960.959 36.1911L1131.43 183.212C1143.45 193.714 1140.94 197.866 1125.68 192.981L833.793 96.0254Z" fill="url(#paint26_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M365.429 154.394C349.659 157.569 346.905 152.196 359.422 142.183L516.627 16.6534C529.144 6.64031 550.922 4.44232 565.441 11.5247L684.597 70.6263C698.866 77.7088 697.865 86.2565 682.094 89.4314L365.429 154.394Z" fill="url(#paint27_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M788.233 57.6826C773.964 65.0092 750.684 65.2535 736.415 58.171L643.293 12.0132C629.025 4.93076 630.276 -0.44211 646.548 0.290554L848.562 9.32675C864.583 10.0594 866.085 16.6534 852.067 24.2243L788.233 57.6826Z" fill="url(#paint28_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M295.338 149.51C282.821 159.523 268.302 162.698 263.045 156.592C257.789 150.487 264.798 139.497 279.066 132.17L362.926 88.4545C377.195 81.1278 378.447 83.0816 366.181 93.0947L295.338 149.51Z" fill="url(#paint29_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M187.697 1143.49C193.955 1138.36 207.723 1143.49 218.737 1155.21L280.068 1221.4C290.832 1233.12 289.08 1234.83 276.313 1225.55L199.712 1170.11C186.946 1160.83 181.439 1148.86 187.697 1143.49Z" fill="url(#paint30_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1248.09 252.327C1254.34 247.198 1265.61 254.037 1273.12 267.957L1317.93 349.772C1325.44 363.692 1323.43 364.913 1313.17 352.946L1255.34 283.832C1245.33 271.865 1242.08 257.456 1248.09 252.327Z" fill="url(#paint31_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M1213.29 200.064C1226.31 209.344 1231.56 221.311 1225.56 226.684C1219.3 231.812 1205.53 226.684 1194.52 214.961L1133.18 148.777C1122.42 137.055 1124.17 135.345 1136.94 144.625L1213.29 200.064Z" fill="url(#paint32_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <path d="M157.657 1086.34C167.921 1098.55 171.175 1112.72 164.917 1117.85C158.659 1122.98 147.394 1116.14 139.884 1102.22L95.0754 1020.4C87.5656 1006.48 89.5683 1005.26 99.8317 1017.23L157.657 1086.34Z" fill="url(#paint33_linear_4498_37235)" data-sentry-element="path" data-sentry-source-file="Orbs.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="Orbs.tsx">
      <linearGradient id="paint0_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint1_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint2_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint3_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint4_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint5_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint6_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint7_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint8_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint9_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint10_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint11_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint12_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint13_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint14_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint15_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint16_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint17_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint18_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint19_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint20_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint21_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint22_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint23_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint24_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint25_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint26_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint27_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint28_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint29_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint30_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint31_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint32_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
      <linearGradient id="paint33_linear_4498_37235" x1="706.626" y1="0.223633" x2="707.95" y2="1668.39" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Orbs.tsx">
        <stop stopColor="#DA88DE" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="0.373617" stopColor="#5E75E8" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
        <stop offset="1" stopColor="#80C6EB" data-sentry-element="stop" data-sentry-source-file="Orbs.tsx" />
      </linearGradient>
    </defs>
  </svg>;
export default Orbs;