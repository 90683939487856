import React from 'react';
import { useTheme } from '@chakra-ui/react';
const TelegramIcon = () => {
  const theme = useTheme();
  return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 64 64" data-sentry-element="svg" data-sentry-component="TelegramIcon" data-sentry-source-file="TelegramIcon.tsx">
      <linearGradient id="EEzBmxT86u8E~6vCqQtLma_114954_gr1" x1="29.5" x2="29.5" y1="19.006" y2="46" gradientUnits="userSpaceOnUse" spreadMethod="reflect" data-sentry-element="linearGradient" data-sentry-source-file="TelegramIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="TelegramIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="TelegramIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#EEzBmxT86u8E~6vCqQtLma_114954_gr1)" d="M22.007,35.25L39.022,25L27.011,37.5l10.535,8.275c1.887,0.83,2.574-0.899,2.574-0.899 l4.825-24.501c0,0,0.082-0.625,0.045-0.75c-0.344-1.168-2.228-0.325-2.227-0.324L15.206,30.188c0,0-1.304,0.449-1.201,1.278 c0.103,0.83,1.167,1.209,1.167,1.209l6.837,2.575" data-sentry-element="path" data-sentry-source-file="TelegramIcon.tsx"></path>
      <linearGradient id="EEzBmxT86u8E~6vCqQtLmb_114954_gr2" x1="29.5" x2="29.5" y1="5.833" y2="58.262" gradientUnits="userSpaceOnUse" spreadMethod="reflect" data-sentry-element="linearGradient" data-sentry-source-file="TelegramIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="TelegramIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="TelegramIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#EEzBmxT86u8E~6vCqQtLmb_114954_gr2)" d="M45.949,19.343c-0.23-0.784-0.859-1.269-1.727-1.33c-0.175-0.013-1.159,0.027-1.823,0.365 l-0.003-0.007L14.881,29.243C13.996,29.547,13.02,30.266,13,31.33c-0.002,0.084,0.003,0.171,0.014,0.26 c0.173,1.4,1.654,1.968,1.807,2.021l6.377,2.402c0.49,1.575,2.108,6.774,2.456,7.886c0.234,0.744,0.62,1.709,1.327,1.973 C25.185,45.956,25.402,46,25.628,46c0.614,0,1.014-0.312,1.163-0.447l3.903-3.89l6.235,4.897c0.065,0.052,0.138,0.096,0.215,0.129 c0.517,0.228,0.976,0.31,1.367,0.31c0.409,0,0.745-0.089,0.998-0.193c1.067-0.438,1.494-1.447,1.54-1.561 c0.022-0.057,0.04-0.116,0.052-0.176l4.835-24.564C46.047,19.669,45.987,19.472,45.949,19.343z M23.195,35.701l9.872-5.947 l-6.775,7.053c-0.12,0.124-0.188,0.279-0.229,0.442c-0.003,0.012-1.006,4.43-1.006,4.43L23.195,35.701z M43.963,20.188l-4.799,24.37 c-0.057,0.098-0.2,0.311-0.413,0.398c-0.179,0.073-0.412,0.055-0.694-0.053l-9.559-7.507l11.245-11.704 c0.348-0.361,0.374-0.925,0.061-1.316c-0.312-0.391-0.867-0.491-1.297-0.232l-16.601,10l-6.389-2.406 c-0.196-0.075-0.398-0.221-0.481-0.323c0.109-0.096,0.329-0.221,0.537-0.296L43.131,20.23c0.017-0.006,0.033-0.014,0.05-0.021 c0.251-0.11,0.59-0.192,0.803-0.205C43.974,20.104,43.963,20.187,43.963,20.188z" data-sentry-element="path" data-sentry-source-file="TelegramIcon.tsx"></path>
      <linearGradient id="EEzBmxT86u8E~6vCqQtLmc_114954_gr3" x1="32" x2="32" y1="5.833" y2="58.262" gradientUnits="userSpaceOnUse" spreadMethod="reflect" data-sentry-element="linearGradient" data-sentry-source-file="TelegramIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="TelegramIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="TelegramIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#EEzBmxT86u8E~6vCqQtLmc_114954_gr3)" d="M32,58C17.663,58,6,46.336,6,32S17.663,6,32,6s26,11.664,26,26S46.337,58,32,58z M32,8 C18.767,8,8,18.767,8,32s10.767,24,24,24s24-10.767,24-24S45.233,8,32,8z" data-sentry-element="path" data-sentry-source-file="TelegramIcon.tsx"></path>
    </svg>;
};
export default TelegramIcon;