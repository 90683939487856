import React from 'react';
const BullIcon = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="BullIcon" data-sentry-source-file="BullIcon.tsx">
    <path d="M52,95c-10.521,0-14.954-1.982-16.821-3.645c-0.745-0.658-1.345-1.512-1.72-2.456	C33.213,88.283,31,82.491,31,73.5c0-0.902,0.192-1.807,0.558-2.633c-1.104-3.44-2.672-3.774-4.194-7.015	c-1.227-2.606-2.478-5.268-3.468-7.976c-0.748,0.088-1.504,0.133-2.266,0.133c-6.923,0-12.065-3.588-13.026-4.307	c-1.763-1.327-2.727-3.419-2.591-5.605c0.136-2.19,1.354-4.149,3.26-5.24c0.263-0.149,0.536-0.304,0.819-0.462	C6.221,37.125,4,32.294,4,26.5c0-9.865,6.341-16.075,7.063-16.751c1.219-1.131,2.791-1.748,4.438-1.748	c0.893,0,1.761,0.179,2.58,0.533C20.468,9.573,22,11.913,22,14.5c0.008,1.152,0.567,5.5,2.16,5.5h9.452	c2.503-0.651,8.845-2,18.388-2c9.313,0,15.109,1.315,17.494,2h9.343c1.593,0,2.152-4.349,2.16-5.518	c0.009-2.588,1.547-4.922,3.92-5.947C85.727,8.183,86.6,8,87.498,8c1.648,0,3.221,0.619,4.428,1.742	c0.73,0.683,7.071,6.893,7.071,16.758c0,5.794-2.221,10.625-6.092,13.896c0.282,0.157,0.554,0.311,0.816,0.46	c1.909,1.093,3.127,3.052,3.263,5.243c0.136,2.186-0.828,4.277-2.579,5.596c-0.973,0.728-6.116,4.315-13.036,4.315c0,0,0,0-0.001,0	c-0.763,0-1.519-0.044-2.267-0.132c-0.99,2.707-2.242,5.368-3.468,7.976c-1.522,3.239-3.091,3.574-4.194,7.014	c0.366,0.831,0.558,1.732,0.558,2.633c0,8.991-2.213,14.783-2.465,15.414c-0.143,0.355-0.317,0.7-0.52,1.024	C66.832,93.438,61.585,95,52,95z" opacity=".35" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#f2f2f2" d="M50,93c-10.521,0-14.954-1.982-16.821-3.645c-0.745-0.658-1.345-1.512-1.72-2.456	C31.213,86.283,29,80.491,29,71.5c0-0.902,0.192-1.807,0.558-2.633c-1.104-3.44-2.672-3.774-4.194-7.015	c-1.227-2.606-2.478-5.268-3.468-7.976c-0.748,0.088-1.504,0.133-2.266,0.133c-6.923,0-12.065-3.588-13.026-4.307	c-1.763-1.327-2.727-3.419-2.591-5.605c0.136-2.19,1.354-4.149,3.26-5.24c0.263-0.149,0.536-0.304,0.819-0.462	C4.221,35.125,2,30.294,2,24.5C2,14.635,8.341,8.425,9.063,7.749c1.219-1.131,2.791-1.748,4.438-1.748	c0.893,0,1.761,0.179,2.58,0.533C18.468,7.573,20,9.913,20,12.5c0.008,1.152,0.567,5.5,2.16,5.5h9.452c2.503-0.651,8.845-2,18.388-2	c9.313,0,15.109,1.315,17.494,2h9.343c1.593,0,2.152-4.349,2.16-5.518c0.009-2.588,1.547-4.922,3.92-5.947	C83.727,6.183,84.6,6,85.498,6c1.648,0,3.221,0.619,4.428,1.742c0.73,0.683,7.071,6.893,7.071,16.758	c0,5.794-2.221,10.625-6.092,13.896c0.282,0.157,0.554,0.311,0.816,0.46c1.909,1.093,3.127,3.052,3.263,5.243	c0.136,2.186-0.828,4.277-2.579,5.596c-0.973,0.728-6.116,4.315-13.036,4.315c0,0,0,0-0.001,0c-0.763,0-1.519-0.044-2.267-0.132	c-0.99,2.707-2.242,5.368-3.468,7.976c-1.522,3.239-3.091,3.574-4.194,7.014c0.366,0.831,0.558,1.732,0.558,2.633	c0,8.991-2.213,14.783-2.465,15.414c-0.143,0.355-0.317,0.7-0.52,1.024C64.832,91.438,59.585,93,50,93z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#d9eeff" d="M35.5,36H22.16C14.076,36,7.5,31.66,7.5,24c0-7.34,6-13,6-13s0,13,8.66,13H35.5V36z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#d9eeff" d="M63.5,36h13.34c8.084,0,14.66-4.34,14.66-12c0-7.34-6-13-6-13s0,13-8.66,13H63.5V36z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#ff7575" d="M73.5,44c0-10-7-20-7-20s-5.536-2-15.75-2c-11.143,0-18.25,2-18.25,2s-7,10-7,20	c0,8.44,8.214,14,11,25l-2,2c0,4,0.143,10,2,13c0,0,5.893,3,14.25,3s11.75-3,11.75-3c1.857-3,2-9,2-13l-2-2	C64.357,59,73.5,52.44,73.5,44z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#ff7575" d="M27.5,36c0,0-11,4-18,8c0,0,10.312,7.385,19-1L27.5,36z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#ff7575" d="M71.5,36c0,0,11,4,18,8c0,0-10.312,7.385-19-1L71.5,36z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#fff" d="M35.5,36H22.16C14.076,36,7.5,31.66,7.5,24c0-7.34,6-13,6-13s0,13,8.66,13H35.5V36z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#fff" d="M63.5,36h13.34c8.084,0,14.66-4.34,14.66-12c0-7.34-6-13-6-13s0,13-8.66,13H63.5V36z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#ff7575" d="M73.5,44c0-10-7-20-7-20s-5.536-2-15.75-2c-11.143,0-18.25,2-18.25,2s-7,10-7,20 c0,8.44,8.214,14,11,25l-2,2c0,4,0.143,10,2,13c0,0,5.893,3,14.25,3s11.75-3,11.75-3c1.857-3,2-9,2-13l-2-2 C64.357,59,73.5,52.44,73.5,44z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#ff7575" d="M27.5,36c0,0-11,4-18,8c0,0,10.312,7.385,19-1L27.5,36z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#ff7575" d="M71.5,36c0,0,11,4,18,8c0,0-10.312,7.385-19-1L71.5,36z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#40396e" d="M27.063,35.114C27.063,35.114,42,35,42,51c0,11.191-4,19-4,19l-6.804-11.017	l-4.4-11.491l-1.15-1.501l-9.495,1.335l-4.767-3.323L27.063,35.114z" opacity=".35" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#40396e" d="M71.931,35.114C71.931,35.114,57,36,57,51c0,11.191,3.994,19,3.994,19l6.804-11.017	l4.4-11.491l1.15-1.501l9.495,1.335l4.767-3.323L71.931,35.114z" opacity=".35" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#f4a3be" d="M62,84l-12.366,2L37,84V71c0,0,4.5-2,12.5-2S62,71,62,71V84z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="none" stroke="#40396e" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" d="M50,86.5	c10.25,0,11.497-2,11.497-2s2-5,2-13l-1-2c2.651-10.468,9.274-15.952,10-24c8,5,16-1,16-1c-5.87-3.354-16-8-16-8h4.5	c8.084,0,13.5-4.34,13.5-12c0-7.34-5-12-5-12s0,12-8.66,12h-10.34c0,0-5.354-2-16.497-2s-17.5,2-17.5,2H22.16	c-8.66,0-8.66-12-8.66-12s-5,4.66-5,12c0,7.66,5.416,12,13.5,12h4.5c0,0-10.13,4.646-16,8c0,0,8,6,16,1	c0.726,8.048,7.349,13.532,10,24l-1,2c0,8,2,13,2,13S39.75,86.5,50,86.5z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#f2f2f2" d="M31,43c0,0,7-0.541,7,6C38,49,31,49.538,31,43z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#40396e" d="M33.404,43.22C33.15,43.605,33,44.065,33,44.562c0,1.349,1.094,2.443,2.443,2.443	c0.786,0,1.478-0.378,1.924-0.954C36.478,44.289,34.812,43.538,33.404,43.22z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#f2f2f2" d="M68,43c0,0-7-0.541-7,6C61,49,68,49.538,68,43z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <path fill="#40396e" d="M65.596,43.22C65.85,43.605,66,44.065,66,44.562c0,1.349-1.094,2.443-2.443,2.443	c-0.786,0-1.478-0.378-1.924-0.954C62.522,44.289,64.188,43.538,65.596,43.22z" data-sentry-element="path" data-sentry-source-file="BullIcon.tsx"></path>
    <ellipse cx="43.5" cy="76" fill="#40396e" rx="2.5" ry="2" data-sentry-element="ellipse" data-sentry-source-file="BullIcon.tsx"></ellipse>
    <ellipse cx="55.5" cy="76" fill="#40396e" rx="2.5" ry="2" data-sentry-element="ellipse" data-sentry-source-file="BullIcon.tsx"></ellipse>
  </svg>;
export default BullIcon;