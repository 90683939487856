import React, { useState, useEffect } from 'react';
import BNB from '~/app/components/icons/DeFi/Chains/BNB';
import DAIIcon from '~/app/components/icons/DeFi/Coins/DAI';
import USDCIcon from '~/app/components/icons/DeFi/Coins/USDC';
import RainbowKit from '~/app/components/icons/DeFi/Wallets/RainbowKit';
import Frax from '~/app/components/icons/DeFi/Protocols/Frax';
import Tether from '~/app/components/icons/DeFi/Coins/Tether';
import Ocean from '~/app/components/icons/DeFi/Coins/Ocean';
import Beefy from '~/app/components/icons/DeFi/Protocols/Beefy';
import SolanaIcon from '~/app/components/icons/DeFi/Chains/Solana';
import Orbs from '~/app/components/icons/DeFi/Protocols/Orbs';
import Osmo from '~/app/components/icons/DeFi/Chains/Osmo';
import BaseBlue from '~/app/components/icons/DeFi/Chains/BaseBlue';
import Bitcoin from '~/app/components/icons/DeFi/Chains/Bitcoin';
import OPIcon from '~/app/components/icons/DeFi/Chains/Op';
import MetaMask from '~/app/components/icons/DeFi/Wallets/Metamask';
import CoinbaseWallet from '~/app/components/icons/DeFi/Wallets/CoinbaseWallet';
import EthereumIcon from '~/app/components/icons/DeFi/Chains/EthereumIcon';
import USDPlus from '~/app/components/icons/DeFi/Coins/USDP';
import Rabby from '~/app/components/icons/DeFi/Wallets/Rabby';
import Aave from '~/app/components/icons/DeFi/Protocols/Aave';
import Ionic from '~/app/components/icons/DeFi/Protocols/Ionic';
import Odos from '~/app/components/icons/DeFi/Protocols/Odos';
import Shiba from '~/app/components/icons/DeFi/Coins/Shiba';
import Injective from '~/app/components/icons/DeFi/Chains/Inj';
import Sui from '~/app/components/icons/DeFi/Chains/SUI';
export const componentMap = {
  0: Tether,
  1: OPIcon,
  2: Injective,
  3: Bitcoin,
  4: RainbowKit,
  5: Shiba,
  6: SolanaIcon,
  7: Rabby,
  8: EthereumIcon,
  9: Ionic,
  10: USDCIcon,
  11: Frax,
  12: CoinbaseWallet,
  13: DAIIcon,
  14: Beefy,
  15: Osmo,
  16: BNB,
  17: BaseBlue,
  18: Orbs,
  19: Ocean,
  20: Aave,
  21: Sui,
  22: MetaMask,
  23: USDPlus,
  24: Ocean,
  25: Odos

  // 22: bsx,
};