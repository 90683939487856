import React from 'react';
const StakingIcon = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="StakingIcon" data-sentry-source-file="StakingIcon.tsx">
    <path d="M55.372,96.541c-2.356,0-4.655-0.453-6.831-1.345l-18.7-7.891l-13.78,2.149c-0.334,0.052-0.667,0.077-0.997,0.077 c-1.552,0-3.055-0.556-4.232-1.565c-1.441-1.234-2.27-3.034-2.27-4.935v-18c0-3.067,2.18-5.744,5.183-6.365l17.801-3.684 c1.315-0.275,2.672-0.417,4.022-0.417c2.426,0,4.807,0.445,7.078,1.322l11.072,3.586l6.256-0.374 c0.613-0.071,1.223-0.107,1.818-0.107c4.268,0,6.217,4.503,9.907,4.882l9.411-2.791c1.034-0.311,2.12-0.47,3.214-0.47 c2.174,0,4.291,0.627,6.119,1.814c2.774,1.801,4.607,4.716,5.029,7.998c0.616,4.808-1.902,9.452-6.267,11.557L63.239,94.735 C60.778,95.922,58.069,96.541,55.372,96.541z" opacity=".35" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
    <circle cx="52.5" cy="36.5" r="24.5" opacity=".35" data-sentry-element="circle" data-sentry-source-file="StakingIcon.tsx"></circle>
    <path fill="#f2f2f2" d="M53.372,94.541c-2.356,0-4.655-0.453-6.831-1.345l-18.7-7.891l-13.78,2.149 c-0.334,0.052-0.667,0.077-0.997,0.077c-1.552,0-3.055-0.556-4.232-1.565c-1.441-1.234-2.27-3.034-2.27-4.935v-18 c0-3.067,2.18-5.744,5.183-6.365l17.801-3.684c1.315-0.275,2.672-0.417,4.022-0.417c2.426,0,4.807,0.445,7.078,1.322l11.072,3.586 l6.256-0.374c0.613-0.071,1.223-0.107,1.818-0.107c4.268,0,7.818,1.805,9.907,4.882l9.411-2.791c1.034-0.311,2.12-0.47,3.214-0.47 c2.174,0,4.291,0.627,6.119,1.814c2.774,1.801,4.607,4.716,5.029,7.998c0.616,4.808-1.902,9.452-6.267,11.557L61.239,92.735 C58.778,93.922,56.069,94.541,53.372,94.541z" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
    <circle cx="50.5" cy="34.5" r="24.5" fill="#f2f2f2" data-sentry-element="circle" data-sentry-source-file="StakingIcon.tsx"></circle>
    <circle cx="50.5" cy="34.5" r="18" fill="#ffc571" data-sentry-element="circle" data-sentry-source-file="StakingIcon.tsx"></circle>
    <path fill="#ffb789" d="M42.271,73.398l9.686,2.583c0.786,0.21,1.617,0.175,2.383-0.1l7.85-2.819 c1.724-0.619,2.873-2.253,2.873-4.085l0,0c0-2.621-2.306-4.644-4.905-4.303l-7.714,0.255L38.456,60.01 c-2.41-0.965-5.051-1.196-7.592-0.663L13.063,63.03v18l13.877-2.157c1.098-0.181,2.224-0.053,3.254,0.369l18.815,7.94 c3.02,1.239,6.424,1.137,9.365-0.281l26.009-12.773c1.839-0.887,2.904-2.85,2.644-4.875l0,0c-0.373-2.904-3.262-4.779-6.066-3.938 L65.063,70.03" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
    <path fill="#40396e" d="M53.373,89.541c-1.677,0-3.354-0.323-4.934-0.972l-18.828-7.945 c-0.759-0.311-1.603-0.405-2.428-0.271l-13.89,2.159c-0.438,0.066-0.874-0.059-1.206-0.343c-0.333-0.285-0.524-0.701-0.524-1.14 v-18c0-0.711,0.5-1.325,1.196-1.469l17.801-3.684c2.819-0.591,5.726-0.341,8.404,0.72l12.111,3.923l7.351-0.438 c4.029-0.513,7.093,1.21,8.003,4.498c0.129,0.464,0.205,0.937,0.23,1.412l13.874-4.115c1.755-0.523,3.647-0.256,5.188,0.744 c1.54,1,2.559,2.619,2.792,4.44c0.343,2.67-1.056,5.248-3.48,6.418L59.035,88.248C57.25,89.108,55.312,89.541,53.373,89.541z M27.915,77.293c0.969,0,1.938,0.188,2.848,0.562L49.592,85.8c2.609,1.069,5.577,0.981,8.131-0.25l25.999-12.769 c1.269-0.611,1.996-1.951,1.817-3.338c-0.121-0.945-0.649-1.786-1.45-2.306c-0.8-0.52-1.784-0.659-2.696-0.386L65.49,71.469 c-0.196,0.059-0.396,0.075-0.589,0.053c-0.187,0.002-0.331-0.023-0.495-0.079c-0.784-0.267-1.204-1.118-0.938-1.903 c0.244-0.719,0.269-1.445,0.071-2.158c-0.686-2.473-3.287-2.512-4.776-2.317c-0.035,0.005-0.07,0.008-0.105,0.01l-7.685,0.459 c-0.188,0.011-0.374-0.012-0.552-0.07l-12.427-4.025c-0.032-0.01-0.064-0.021-0.096-0.034c-2.14-0.856-4.466-1.06-6.727-0.588 l-16.608,3.438v15.027l12.146-1.889C27.109,77.325,27.512,77.293,27.915,77.293z" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
    <path fill="#ffe8d9" d="M59.55,66.555c-0.437,0-0.839,0.144-1.168,0.382c-0.027,0.481-0.009,1.092,0.132,1.815 c0.126,0.647,0.393,1.259,0.678,1.773c0.117,0.021,0.236,0.036,0.359,0.036c1.106,0,2.003-0.897,2.003-2.003 S60.656,66.555,59.55,66.555z" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
    <path fill="#40396e" d="M53.098,76.531c-0.719,0-1.432-0.088-2.122-0.263l-9.336-2.792c0,0,9.094,0.641,12.654-0.067 l7.451-1.782c0.941-0.393,2.368-2.173,1.793-4.247c-0.221-0.799,0.247-1.625,1.045-1.846c0.796-0.222,1.625,0.247,1.846,1.045 c0.973,3.513-1.187,6.98-3.733,7.896c-0.052,0.019-0.105,0.034-0.159,0.047l-7.583,1.813 C54.339,76.466,53.716,76.531,53.098,76.531z" opacity=".35" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
    <path fill="#40396e" d="M50.5,54C39.748,54,31,45.252,31,34.5S39.748,15,50.5,15S70,23.748,70,34.5S61.252,54,50.5,54z M50.5,18C41.402,18,34,25.402,34,34.5S41.402,51,50.5,51S67,43.598,67,34.5S59.598,18,50.5,18z" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
    <path fill="#fff" d="M43.5,43.026v-17.5h7.174c2.109,0,3.705,0.407,4.795,1.221c1.09,0.814,1.634,2.006,1.634,3.575 c0,0.858-0.219,1.614-0.653,2.266c-0.438,0.653-1.046,1.131-1.825,1.436c0.889,0.226,1.589,0.676,2.102,1.361 c0.514,0.679,0.774,1.509,0.774,2.497c0,1.682-0.533,2.957-1.6,3.821c-1.067,0.865-2.584,1.305-4.556,1.323H43.5z M47.09,32.705 h3.916c1.851-0.031,2.78-0.739,2.78-2.194c0-0.813-0.235-1.399-0.704-1.756c-0.469-0.357-1.21-0.537-2.223-0.537H47.09V32.705z M47.09,35.397v5.385h4.347c0.862,0,2.832-0.421,2.832-2.341c0-1.921-1.002-3.019-2.692-3.043H47.09z" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
    <path fill="#fff" d="M46.192,22.833h2.692v4.487h-2.692V22.833z M50.679,22.833h2.692v4.487h-2.692V22.833z M46.192,41.679h2.692v4.487h-2.692V41.679z M50.679,41.679h2.692v4.487h-2.692V41.679z" data-sentry-element="path" data-sentry-source-file="StakingIcon.tsx"></path>
  </svg>;
export default StakingIcon;