import React from 'react';
const Injective = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" x="0px" y="0px" style={{
  width: '99%',
  height: '99%'
}} viewBox="0 0 400 400" data-sentry-element="svg" data-sentry-component="Injective" data-sentry-source-file="Inj.tsx">
    <defs data-sentry-element="defs" data-sentry-source-file="Inj.tsx">
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="182.2531" x2="400" y2="182.2531" gradientTransform="matrix(1 0 0 -1 0 399.89)" data-sentry-element="linearGradient" data-sentry-source-file="Inj.tsx">
        <stop offset="0" style={{
        stopColor: '#0082FA'
      }} data-sentry-element="stop" data-sentry-source-file="Inj.tsx" />
        <stop offset="1" style={{
        stopColor: '#00F2FE'
      }} data-sentry-element="stop" data-sentry-source-file="Inj.tsx" />
      </linearGradient>
      <linearGradient id="SVGID_00000027590406948247584680000011222828394992047503_" gradientUnits="userSpaceOnUse" x1="2.939412e-05" y1="217.5268" x2="400" y2="217.5268" gradientTransform="matrix(1 0 0 -1 0 399.89)" data-sentry-element="linearGradient" data-sentry-source-file="Inj.tsx">
        <stop offset="0" style={{
        stopColor: '#0082FA'
      }} data-sentry-element="stop" data-sentry-source-file="Inj.tsx" />
        <stop offset="1" style={{
        stopColor: '#00F2FE'
      }} data-sentry-element="stop" data-sentry-source-file="Inj.tsx" />
      </linearGradient>
    </defs>
    <path fill="url(#SVGID_1_)" d="M48.5,69.1C51,66,53.6,63,56.2,60c0.1-0.1,0.4-0.2,0.5-0.3c0.2-0.3,0.6-0.5,0.9-0.8l0.2-0.3
      c1.8-1.7,3.8-3.5,6-5.2c8-6,16.2-10.6,24.9-13.7c28-9.8,59.1-3.8,83.5,19.3c34.1,31.9,31,83.4,3.8,117.6
      c-34.4,51-93.4,122.1-11.7,185.8c14.7,11.5,25.6,20.9,71.9,34.3c-30.3,5.6-58.4,3.8-89.6-4.1c-22.1-12.5-56.9-39.2-68.7-75.3
      c-17.9-54.7,31.5-136.6,55.3-168.1c32.7-43.6-20.2-90.8-59.3-38.1C53.7,138.6,18,216.3,30.4,274c7.2,32.7,16.9,56.5,55.2,89.3
      c-7.1-4.2-14-8.9-20.7-14.3C-24,266.1-13.7,137.9,48.5,69.1z" data-sentry-element="path" data-sentry-source-file="Inj.tsx" />
    <path fill="url(#SVGID_00000027590406948247584680000011222828394992047503_)" d="M351.5,330.9c-2.5,3.1-5.1,6.1-7.7,9.1
      c-0.1,0.1-0.4,0.2-0.5,0.3c-0.2,0.3-0.6,0.5-0.9,0.8l-0.2,0.3c-1.8,1.7-3.8,3.5-6,5.1c-8,6-16.2,10.6-24.9,13.7
      c-28,9.8-59.1,3.8-83.5-19.3c-34.1-31.9-31-83.4-3.8-117.6c34.4-51,93.4-122.1,11.7-185.8c-14.7-11.5-25.6-20.9-71.9-34.3
      c30.3-5.6,58.4-3.8,89.6,4.1c22.1,12.5,56.9,39.2,68.7,75.3c17.9,54.7-31.5,136.6-55.3,168.1c-32.7,43.6,20.2,90.8,59.3,38.1
      c20.4-27.5,56.1-105.2,43.7-162.9c-7.2-32.7-16.9-56.5-55.2-89.3c7.1,4.2,14,8.9,20.7,14.3C424,133.9,413.7,262.1,351.5,330.9z" data-sentry-element="path" data-sentry-source-file="Inj.tsx" />
  </svg>;
export default Injective;