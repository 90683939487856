import { Flex, Text, Box, TextProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
interface StandardGradientProps extends TextProps {
  children: React.ReactNode;
  fontSize?: string | {
    base: string;
    xs: string;
    sm: string;
    lg: string;
  };
}
const StandardGradient: React.FC<StandardGradientProps> = ({
  children,
  fontSize
}) => {
  return <Text fontSize={fontSize} color="transparent" textAlign="center" background="settingstextgradient" userSelect="none" zIndex={1} backgroundClip="text" data-sentry-element="Text" data-sentry-component="StandardGradient" data-sentry-source-file="StandardGradient.tsx">
      {children}
    </Text>;
};
export default StandardGradient;