import { BoxProps, Flex, Text } from '@chakra-ui/react';
import React, { memo } from 'react';
interface Props extends BoxProps {
  pool: {
    symbol?: string;
    feeTier?: number;
  };
}
const PoolSymbol = ({
  pool
}: Props) => {
  const symbol = pool?.symbol;
  return <Flex data-sentry-element="Flex" data-sentry-component="PoolSymbol" data-sentry-source-file="PoolSymbol.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="PoolSymbol.tsx">{symbol} </Text>
    </Flex>;
};
export default memo(PoolSymbol);