import React from 'react';
import { Flex, FlexProps, Popover, PopoverContent, PopoverTrigger, Text, PlacementWithLogical, ResponsiveValue, useBreakpointValue } from '@chakra-ui/react';
import TokenAvatar from './TokenAvatar';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
export interface TokenAvatarSetInListTokenData {
  address: string;
  logoURI?: string;
  symbol?: string;
}
interface Props extends FlexProps {
  tokens: TokenAvatarSetInListTokenData[];
  imageSize?: number | {
    base: number;
    [key: string]: number;
  };
  maxAssetsPerLine?: number;
  width: number | {
    base: number;
    [key: string]: number;
  };
  placement?: PlacementWithLogical;
  showPopover?: boolean;
}
export function TokenAvatarSetInList({
  tokens,
  imageSize = 22,
  maxAssetsPerLine = 3,
  width,
  showPopover = true,
  ...rest
}: Props) {
  const numTokens = Math.min(tokens.length, maxAssetsPerLine);
  const networkConfig = useNetworkConfig();
  const resolveResponsiveValue = (value: number | {
    base: number;
    [key: string]: number;
  }) => {
    if (typeof value === 'number') return value;
    return useBreakpointValue(value) || value.base;
  };
  const resolvedImageSize = resolveResponsiveValue(imageSize);
  const resolvedWidth = resolveResponsiveValue(width);
  function leftOffsetFor(index: number) {
    const spacer = -2.5 * numTokens + resolvedImageSize / numTokens;
    return (resolvedWidth - resolvedImageSize + spacer) / (maxAssetsPerLine - 1) * index;
  }
  // function leftOffsetFor(index: number) {
  //   const spacer = -2.5 * numTokens + imageSize / numTokens;
  //   return ((width - imageSize + spacer) / (maxAssetsPerLine - 1)) * index;
  // }

  if (!tokens || !tokens.length) return <></>;
  const content = <Flex {...rest} position="relative" height={`${resolvedImageSize}px`} width={`${leftOffsetFor(numTokens - 1) + resolvedImageSize + 1}px`}>
      {tokens.slice(0, maxAssetsPerLine).reverse().map((token, i) => {
      const ethSymbol = token?.symbol === 'ETH' ? networkConfig.eth.address : undefined;
      return <TokenAvatar key={i} address={token?.address || ethSymbol} logoURI={token?.logoURI} alt={token?.symbol || 'Token'} size={resolvedImageSize} style={{
        position: 'absolute',
        left: `${leftOffsetFor(numTokens - i - 1)}px`
      }} />;
    })}
    </Flex>;
  if (!showPopover) {
    return content;
  }
  return <Popover trigger="hover" data-sentry-element="Popover" data-sentry-component="TokenAvatarSetInList" data-sentry-source-file="TokenAvatarSetInList.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="TokenAvatarSetInList.tsx">
        <button aria-label="View Tokens">{content}</button>
      </PopoverTrigger>
      <PopoverContent w="200px" padding="4" borderRadius="16px" bgColor="transparent" backdropFilter="blur(12px)" color="text.100" boxShadow="0 0 12px black" data-sentry-element="PopoverContent" data-sentry-source-file="TokenAvatarSetInList.tsx">
        {tokens.map((token, index) => <Flex alignItems="center" p="1" key={index}>
            <TokenAvatar address={token?.address} logoURI={token?.logoURI} alt={token?.symbol || 'Token'} size="xs" />
            <Text ml="2">{token?.symbol || ''}</Text>
          </Flex>)}
      </PopoverContent>
    </Popover>;
}