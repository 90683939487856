import React from 'react';
import * as Avatar from '@radix-ui/react-avatar';
import { useGetTokens } from '~/lib/global/useToken';
import { Circle } from '@chakra-ui/react';
const sizeValues = {
  xs: '20px',
  sm: '24px',
  md: '32px',
  lg: '40px',
  xl: '60px'
};
interface Props {
  address?: string | null;
  logoURI?: string;
  alt?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number | {
    base: number;
    lg?: number;
  };
  mx?: string | number;
  style?: React.CSSProperties;
}
function TokenAvatar({
  address,
  logoURI,
  alt,
  size = 'sm',
  mx,
  style
}: Props) {
  const {
    getToken
  } = useGetTokens();
  const token = address ? getToken(address) : null;
  const finalLogoURI = logoURI || token?.logoURI;
  const getSize = (size: Props['size']) => {
    if (typeof size === 'object') {
      return {
        base: `${size.base}px`,
        lg: size.lg ? `${size.lg}px` : undefined
      };
    }
    if (typeof size === 'number') {
      return {
        base: `${size}px`
      };
    }
    return {
      base: sizeValues[size] || sizeValues['sm']
    };
  };
  const avatarSizes = getSize(size);
  const marginX = typeof mx === 'number' ? `${mx}px` : mx;
  return <Avatar.Root style={{
    width: avatarSizes.base,
    height: avatarSizes.base,
    marginLeft: marginX,
    marginRight: marginX,
    display: 'inline-flex',
    borderRadius: '50%',
    overflow: 'hidden',
    ...style,
    ...(avatarSizes.lg && {
      ['@media (min-width: 1024px)']: {
        width: avatarSizes.lg,
        height: avatarSizes.lg
      }
    })
  }} data-sentry-element="unknown" data-sentry-component="TokenAvatar" data-sentry-source-file="TokenAvatar.tsx">
      {finalLogoURI ? <Avatar.Image src={finalLogoURI} alt={alt} style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%'
    }} /> : <Avatar.Fallback>
          <Circle size={avatarSizes.base} backgroundColor="whiteAlpha.200">
            <span style={{
          color: 'white'
        }}>{alt ? alt[0] : ''}</span>
          </Circle>
        </Avatar.Fallback>}
    </Avatar.Root>;
}
export default TokenAvatar;