import { motion } from 'framer-motion';
import Marquee from 'react-fast-marquee';
import AnimatedDefiIcons from './AnimatedDefiIcons';
import { Box, Flex } from '@chakra-ui/react';
import { componentMap } from '../ComponentMap';
export default function DefiMarquee() {
  return <Flex justify="center" align="center" overflow="hidden" my={{
    base: '0',
    lg: '2'
  }} p="2" maxW={{
    base: '100%',
    lg: '75vw'
  }} data-sentry-element="Flex" data-sentry-component="DefiMarquee" data-sentry-source-file="index.tsx">
      <Marquee speed={100} pauseOnClick={true} pauseOnHover={true} gradient={true} gradientColor="rgba(0, 255, 255, 0.1)" gradientWidth={50} style={{
      borderRadius: '24px',
      padding: '2'
    }} data-sentry-element="Marquee" data-sentry-source-file="index.tsx">
        {Object.keys(componentMap).map((_, index) => <AnimatedDefiIcons key={index} iconIndex={index} />)}
      </Marquee>
    </Flex>;
}