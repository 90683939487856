'use client';

import { Flex, Text, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import ReactCardFlip from 'react-card-flip';
import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import MotionCard from '~/app/components/card/MotionCard';
const SimpleCard = ({
  type,
  delay,
  frontContent,
  backContent,
  isFlipped,
  onClick
}) => {
  const typeStyles = {
    dark: {
      bg: 'insetComponent',
      borderColor: 'highlight',
      boxShadow: 'insetComponentShadow'
    },
    light: {
      bg: 'insetComponent',
      borderColor: 'highlight',
      boxShadow: 'insetComponentShadow'
    }
  };
  return <MotionCard data-sentry-element="MotionCard" data-sentry-component="SimpleCard" data-sentry-source-file="SimpleCard.tsx">
      <Flex direction="column" cursor="pointer" align="center" data-aos="fade-up" data-aos-delay={delay} data-aos-anchor="[data-aos-id-blocks]" bg={typeStyles[type].bg} border="1px solid" borderColor={typeStyles[type].borderColor} borderRadius="32px" backdropFilter="blur(24px)" p="2" w={{
      base: '340px',
      xs: '360px',
      lg: '340px'
    }} h="200px" boxShadow={typeStyles[type].boxShadow} position="relative" data-aos-mirror="true" data-aos-anchor-placement="top-bottom" data-aos-offset="100" onClick={onClick} _hover={{
      '.chevron-icon': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    }} data-sentry-element="Flex" data-sentry-source-file="SimpleCard.tsx">
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" data-sentry-element="ReactCardFlip" data-sentry-source-file="SimpleCard.tsx">
          <div onClick={onClick}>
            {frontContent}

            <Box className="chevron-icon" position="absolute" bottom="10px" right="10px" opacity="0" bg="rgba(255, 255, 255, 0.05)" backdropFilter="blur(24px)" borderRadius="50%" transform="translateY(10px)" transition="opacity 0.2s ease, transform 0.2s ease" data-sentry-element="Box" data-sentry-source-file="SimpleCard.tsx">
              <ChevronRightIcon boxSize={8} color="highlight" data-sentry-element="ChevronRightIcon" data-sentry-source-file="SimpleCard.tsx" />
            </Box>
          </div>

          <div onClick={onClick}>{backContent}</div>
        </ReactCardFlip>
      </Flex>
    </MotionCard>;
};
SimpleCard.propTypes = {
  type: PropTypes.oneOf(['dark', 'light']).isRequired,
  delay: PropTypes.string.isRequired,
  frontContent: PropTypes.node.isRequired,
  backContent: PropTypes.node.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
export default SimpleCard;