import React from 'react';
const Aave = () => <svg style={{
  width: '100%',
  height: '100%'
}} viewBox="0 0 266 139" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Aave" data-sentry-source-file="Aave.tsx">
    <path d="M97.5418 138.533C112.461 138.533 124.556 126.438 124.556 111.518C124.556 96.5987 112.461 84.5039 97.5418 84.5039C82.6221 84.5039 70.5273 96.5987 70.5273 111.518C70.5273 126.438 82.6221 138.533 97.5418 138.533Z" fill="white" style={{
    fill: '#fff'
  }} data-sentry-element="path" data-sentry-source-file="Aave.tsx" />
    <path d="M168.149 138.533C183.069 138.533 195.164 126.438 195.164 111.518C195.164 96.5987 183.069 84.5039 168.149 84.5039C153.23 84.5039 141.135 96.5987 141.135 111.518C141.135 126.438 153.23 138.533 168.149 138.533Z" fill="white" style={{
    fill: '#fff'
  }} data-sentry-element="path" data-sentry-source-file="Aave.tsx" />
    <path d="M132.8 0C59.4497 0 -0.0191954 60.6017 4.64786e-06 135.335H33.9264C33.9264 79.3281 77.8433 33.92 132.8 33.92C187.757 33.92 231.674 79.3281 231.674 135.335H265.6C265.613 60.6017 206.144 0 132.8 0Z" fill="white" style={{
    fill: '#fff'
  }} data-sentry-element="path" data-sentry-source-file="Aave.tsx" />
  </svg>;
export default Aave;