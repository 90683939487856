import React from 'react';
import { useTheme } from '@chakra-ui/react';
const DiscordIcon = () => {
  const theme = useTheme();
  return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 64 64" data-sentry-element="svg" data-sentry-component="DiscordIcon" data-sentry-source-file="DiscordIcon.tsx">
      <linearGradient id="KLDcdiDdqwN_oI5EXsLkCa_xmpxGzSLFcZE_gr1" x1="32" x2="32" y1="-584" y2="-636" gradientTransform="matrix(1 0 0 -1 0 -578)" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="DiscordIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="DiscordIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="DiscordIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#KLDcdiDdqwN_oI5EXsLkCa_xmpxGzSLFcZE_gr1)" d="M32,58C17.663,58,6,46.337,6,32S17.663,6,32,6s26,11.663,26,26S46.337,58,32,58z M32,8	C18.767,8,8,18.767,8,32s10.767,24,24,24s24-10.767,24-24S45.233,8,32,8z" data-sentry-element="path" data-sentry-source-file="DiscordIcon.tsx"></path>
      <linearGradient id="KLDcdiDdqwN_oI5EXsLkCb_xmpxGzSLFcZE_gr2" x1="36.844" x2="36.844" y1="30" y2="36" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="DiscordIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="DiscordIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="DiscordIcon.tsx"></stop>
      </linearGradient>
      <ellipse cx="36.844" cy="33" fill="url(#KLDcdiDdqwN_oI5EXsLkCb_xmpxGzSLFcZE_gr2)" rx="2.656" ry="3" data-sentry-element="ellipse" data-sentry-source-file="DiscordIcon.tsx"></ellipse>
      <linearGradient id="KLDcdiDdqwN_oI5EXsLkCc_xmpxGzSLFcZE_gr3" x1="27.156" x2="27.156" y1="30" y2="36" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="DiscordIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="DiscordIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="DiscordIcon.tsx"></stop>
      </linearGradient>
      <ellipse cx="27.156" cy="33" fill="url(#KLDcdiDdqwN_oI5EXsLkCc_xmpxGzSLFcZE_gr3)" rx="2.656" ry="3" data-sentry-element="ellipse" data-sentry-source-file="DiscordIcon.tsx"></ellipse>
      <linearGradient id="KLDcdiDdqwN_oI5EXsLkCd_xmpxGzSLFcZE_gr4" x1="32" x2="32" y1="10" y2="54" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="DiscordIcon.tsx">
        <stop offset="0" stopColor={theme.colors.primary} data-sentry-element="stop" data-sentry-source-file="DiscordIcon.tsx"></stop>
        <stop offset="1" stopColor={theme.colors.highlight} data-sentry-element="stop" data-sentry-source-file="DiscordIcon.tsx"></stop>
      </linearGradient>
      <path fill="url(#KLDcdiDdqwN_oI5EXsLkCd_xmpxGzSLFcZE_gr4)" d="M32,10c-12.15,0-22,9.85-22,22c0,12.15,9.85,22,22,22s22-9.85,22-22C54,19.85,44.15,10,32,10 z M46.677,39.804C44.057,42.121,39,43.5,39,43.5L37.5,41c0,0,1-0.5,2.5-1.5L39.5,39c0,0-3.5,2-7.5,2s-7.5-2-7.5-2L24,39.5 c1.5,1,2.5,1.5,2.5,1.5L25,43.5c0,0-5.057-1.379-7.677-3.696c-0.216-0.191-0.324-0.471-0.32-0.759 c0.05-3.32,0.663-11.521,4.324-16.323c0.112-0.147,0.265-0.261,0.435-0.332C25.149,20.975,28,20.5,28,20.5l1,2c0,0,1.5-0.5,3-0.5 s3,0.5,3,0.5l1-2c0,0,2.851,0.475,6.237,1.889c0.17,0.071,0.323,0.185,0.435,0.332c3.661,4.802,4.274,13.003,4.324,16.323 C47.001,39.333,46.893,39.612,46.677,39.804z" data-sentry-element="path" data-sentry-source-file="DiscordIcon.tsx"></path>
    </svg>;
};
export default DiscordIcon;