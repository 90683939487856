
export const PartnerGridDimensions = () => {
  const fullSize = 170;
  const adjustment = 10; 
  return {
    fullHeight: `${fullSize}px`,
    fullWidth: `${fullSize}px`,
    adjustedHeight: `${fullSize - adjustment}px`,
    adjustedWidth: `${fullSize - adjustment}px`,
  };
  };


// react card flip requires consistency in the height and width of the 'card' in order for the flip to work properly
// if you dont have it, say by using 100% when setting the height and width of the flipping card, the spacing and orientation gets real weird real fast. 
// this helper function makes it easy because you have to set height and width in two places - 
// one in the partner component (parent), and the other in the map. we want the mapped content to be slightly smaller than the 
// partner component (parent), thus the adjusted value below. use this to set the default size for the component, and it will 
// automatically adjust. 