import React from 'react';
const USDPlus = () => <svg style={{
  width: '100%',
  height: '100%'
}} viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="USDPlus" data-sentry-source-file="USDP.tsx">
    <path d="M85 170C131.944 170 170 131.944 170 85C170 38.0558 131.944 0 85 0C38.0558 0 0 38.0558 0 85C0 131.944 38.0558 170 85 170Z" fill="#004CA8" data-sentry-element="path" data-sentry-source-file="USDP.tsx" />
    <path d="M129.457 127.596C91.7668 135.21 54.9444 110.783 47.2844 73.3112C43.4528 55.1547 47.3878 37.0202 56.7496 22.3759C28.3639 35.4217 11.5764 66.6462 18.0868 98.7092C25.7468 136.181 62.5692 160.608 100.26 152.994C119.687 149.189 135.254 137.651 145.394 122.237C140.339 124.539 135.285 126.455 129.457 127.596Z" fill="#002C7F" data-sentry-element="path" data-sentry-source-file="USDP.tsx" />
    <path d="M80.5418 123.08C68.1354 121.701 60.2831 113.492 59.4411 102.443C59.3571 101.341 60.2612 100.443 61.3657 100.443H67.2235C68.3281 100.443 69.2111 101.343 69.3164 102.443C70.0754 110.365 75.4701 115.047 84.923 115.047C95.1459 115.047 100.622 109.571 100.622 102.999C100.622 84.3785 60.0959 93.8712 60.0959 66.4884C60.0959 57.3608 68.4933 47.8681 80.5418 46.4077V37.4546C80.5418 36.35 81.4372 35.4546 82.5418 35.4546H86.9391C88.0437 35.4546 88.9391 36.35 88.9391 37.4546V46.4077C99.5844 48.1247 107.323 56.6243 108.205 67.046C108.299 68.1466 107.394 69.0442 106.29 69.0442H100.432C99.3273 69.0442 98.4454 68.144 98.3121 67.0475C97.4161 59.6814 91.6036 54.44 84.5579 54.44C75.4303 54.44 69.5886 60.6468 69.5886 66.4884C69.5886 85.1087 110.115 75.616 110.115 102.999C110.115 112.857 102.083 121.619 88.9391 123.445V132.398C88.9391 133.502 88.0437 134.398 86.9391 134.398H82.5418C81.4372 134.398 80.5418 133.502 80.5418 132.398V123.08Z" fill="white" data-sentry-element="path" data-sentry-source-file="USDP.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M150.434 115.644L154.894 118.812C155.899 119.526 155.794 121.049 154.701 121.619L140.261 129.142C139.114 129.74 137.757 128.845 137.855 127.556L139.126 110.679C139.222 109.399 140.679 108.715 141.725 109.458L145.725 112.299C155.935 89.0942 152.162 61.0495 133.887 41.2239C109.466 14.7298 68.3187 12.8607 41.6013 36.8848C41.2627 37.1893 40.7388 37.1709 40.4301 36.836L37.7072 33.882C37.3985 33.5471 37.4222 33.0226 37.7605 32.7178C66.7793 6.57283 111.511 8.58799 138.054 37.3832C158.189 59.2272 162.11 90.2458 150.434 115.644Z" fill="#BEE179" data-sentry-element="path" data-sentry-source-file="USDP.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.6199 54.526L17.1593 51.3578C16.1545 50.644 16.2592 49.1204 17.3523 48.5509L31.7925 41.0274C32.9392 40.43 34.296 41.3245 34.1989 42.6138L32.9276 59.491C32.8312 60.7706 31.3745 61.4546 30.3282 60.7115L26.3287 57.8707C16.118 81.0755 19.8916 109.12 38.1663 128.946C62.5877 155.44 103.735 157.309 130.452 133.285C130.791 132.981 131.315 132.999 131.623 133.334L134.346 136.288C134.655 136.623 134.631 137.147 134.293 137.452C105.274 163.597 60.5423 161.582 33.9997 132.787C13.8645 110.943 9.94379 79.924 21.6199 54.526Z" fill="#BEE179" data-sentry-element="path" data-sentry-source-file="USDP.tsx" />
  </svg>;
export default USDPlus;