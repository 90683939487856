import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { componentMap } from '../ComponentMap';
const size = 100;
const AnimatedDefiIcons = ({
  iconIndex
}) => {
  const randomDuration = Math.random() * 1 + 5;
  const CurrentIcon = componentMap[iconIndex];
  return <motion.div style={{
    width: size,
    height: size,
    margin: '0 15px'
  }} animate={{
    opacity: [1, 0, 1]
  }} transition={{
    duration: randomDuration,
    repeat: Infinity,
    ease: 'easeInOut'
  }} data-sentry-element="unknown" data-sentry-component="AnimatedDefiIcons" data-sentry-source-file="AnimatedDefiIcons.tsx">
      {CurrentIcon && <CurrentIcon />}
    </motion.div>;
};
export default AnimatedDefiIcons;