'use client';

import { Box, Container, Flex, Text } from '@chakra-ui/react';
import OPIcon2 from '~/app/components/icons/DeFi/Chains/OpIcon2';
export default function OptimismFoundation() {
  return <Flex align="flex-end" p="2" direction="column" justify="center" w="100%" data-sentry-element="Flex" data-sentry-component="OptimismFoundation" data-sentry-source-file="OptimismFoundation.tsx">
      <Flex mx="1" data-sentry-element="Flex" data-sentry-source-file="OptimismFoundation.tsx">
        <OPIcon2 data-sentry-element="OPIcon2" data-sentry-source-file="OptimismFoundation.tsx" />
      </Flex>

      <Text ml="2" fontSize="12px" color="border" fontWeight="500" textAlign="left" data-sentry-element="Text" data-sentry-source-file="OptimismFoundation.tsx">
        Stats provided by the Optimism Foundation
      </Text>
    </Flex>;
}