'use client';

import React from 'react';
import Image from 'next/image';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
interface DexLogoByChainProps {
  size?: number;
}
const DexLogoByChain: React.FC<DexLogoByChainProps> = ({
  size = 40
}) => {
  const networkConfig = useNetworkConfig();
  return <Image src={networkConfig.dexIconInRepo} width={size} height={size} alt="Dex Logo" data-sentry-element="Image" data-sentry-component="DexLogoByChain" data-sentry-source-file="index.tsx" />;
};
export default DexLogoByChain;