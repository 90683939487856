import React from 'react';
const EthereumIcon = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" style={{
  width: '100%',
  height: '100%'
}} viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="EthereumIcon" data-sentry-source-file="EthereumIcon.tsx">
    <path d="M84.528,47.483L57.035,7.317C55.821,5.548,54.145,4,52,4c-2.147,0-4.153,1.059-5.363,2.829 L19.144,46.99c-1.038,1.516-1.387,3.41-0.954,5.21c0.356,1.463,1.215,2.743,2.398,3.639c-0.947,2.084-0.752,4.546,0.662,6.489 l25.502,35.008C47.972,99.004,49.934,100,52,100c2.069,0,4.033-0.999,5.253-2.671l25.511-35c1.417-1.943,1.611-4.411,0.658-6.501 c1.177-0.897,1.704-1.683,2.061-3.149C85.912,50.897,85.566,49.006,84.528,47.483z" opacity=".35" data-sentry-element="path" data-sentry-source-file="EthereumIcon.tsx"></path>
    <path fill="#f2f2f2" d="M82.855,44.992L55.362,4.825C54.148,3.056,52.145,2,50,2c-2.147,0-4.153,1.059-5.363,2.829 L17.144,44.99c-1.038,1.516-1.387,3.41-0.954,5.21c0.356,1.463,1.215,2.743,2.398,3.639c-0.947,2.084-0.752,4.546,0.662,6.489 l25.502,35.008C45.972,97.004,47.934,98,50,98c2.069,0,4.033-0.999,5.253-2.671l25.511-35c1.417-1.943,1.611-4.411,0.658-6.501 c1.177-0.897,2.031-2.174,2.389-3.641C84.239,48.406,83.894,46.515,82.855,44.992z" data-sentry-element="path" data-sentry-source-file="EthereumIcon.tsx"></path>
    <polygon fill="#9aa2e6" points="50,8.5 77.492,48.663 50,63.5 22.506,48.663" data-sentry-element="polygon" data-sentry-source-file="EthereumIcon.tsx"></polygon>
    <polygon fill="#9aa2e6" points="50,70.5 75.511,56.5 50,91.5 24.503,56.5" data-sentry-element="polygon" data-sentry-source-file="EthereumIcon.tsx"></polygon>
    <polygon fill="#707cc0" points="50,35 23,49 50,63 77,49" data-sentry-element="polygon" data-sentry-source-file="EthereumIcon.tsx"></polygon>
    <polygon fill="none" stroke="#40396e" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" points="50,8.5 77.492,48.663 50,63.5 22.506,48.663" data-sentry-element="polygon" data-sentry-source-file="EthereumIcon.tsx"></polygon>
    <polygon fill="#707cc0" points="73,59 50,91 50,70" data-sentry-element="polygon" data-sentry-source-file="EthereumIcon.tsx"></polygon>
    <polygon fill="none" stroke="#40396e" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" points="50,70.5 75.511,56.5 50,91.5 24.503,56.5" data-sentry-element="polygon" data-sentry-source-file="EthereumIcon.tsx"></polygon>
    <polygon fill="#40396e" points="77,48.404 50,63.404 50,9.404" opacity=".35" data-sentry-element="polygon" data-sentry-source-file="EthereumIcon.tsx"></polygon>
  </svg>;
export default EthereumIcon;