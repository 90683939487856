import React from 'react';
const NinjaIcon = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="NinjaIcon" data-sentry-source-file="NinjaIcon.tsx">
    <path d="M59.532,99.718c-2.521,0-4.915-0.82-6.92-2.371c-0.211-0.163-0.415-0.332-0.612-0.508	c-0.197,0.176-0.402,0.346-0.613,0.509c-2.006,1.55-4.399,2.369-6.924,2.369c-0.476,0-0.96-0.03-1.439-0.091l-0.454-0.059	c-2.989-0.383-5.665-1.914-7.519-4.311c-1.855-2.401-2.663-5.379-2.275-8.386l2.102-17.645c-0.402,0.048-0.81,0.072-1.221,0.072	c-0.433,0-0.871-0.028-1.303-0.083c-2.711-0.347-5.12-1.728-6.787-3.887c-1.667-2.155-2.395-4.834-2.049-7.539l1.494-11.651	c0.171-1.339,0.601-2.614,1.278-3.79c0.291-0.548,1.301-2.436,4.969-9.283c1.553-3.53,4.445-6.316,7.978-7.756	c-0.063-0.232-0.121-0.46-0.175-0.682c-0.497-2.054-0.77-4.628-0.77-7.255c0-6.508,5.106-12.08,11.879-12.96l0.218-0.023	c0.492-0.043,1.084-0.086,1.733-0.086c0.596,0,1.156,0.036,1.712,0.109c5.98,0.778,10.665,5.222,11.673,10.736	c0.499-0.122,1.016-0.187,1.54-0.187c1.129,0,2.244,0.297,3.226,0.856c3.117,1.794,4.192,5.773,2.412,8.879l-0.63,1.095	c0.324,1.514,0.114,3.147-0.714,4.593c-0.02,0.033-0.039,0.067-0.06,0.101c0.569,0.805,1.06,1.669,1.461,2.58	c0,0,4.655,8.697,4.984,9.311c0.658,1.139,1.094,2.438,1.263,3.769l1.492,11.644c0.347,2.706-0.381,5.385-2.049,7.542	c-1.666,2.157-4.075,3.538-6.782,3.885c-0.443,0.055-0.875,0.081-1.3,0.081c-0.001,0-0.001,0-0.001,0	c-0.413,0-0.822-0.023-1.227-0.072l2.109,17.708c0.381,2.95-0.428,5.923-2.282,8.322c-1.854,2.397-4.529,3.929-7.533,4.314	l-0.427,0.055C60.513,99.686,60.02,99.718,59.532,99.718z" opacity=".35" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <path fill="#f2f2f2" d="M57.532,97.718c-2.521,0-4.915-0.82-6.92-2.371c-0.211-0.163-0.415-0.332-0.612-0.508	c-0.197,0.176-0.402,0.346-0.613,0.509c-2.006,1.55-4.399,2.369-6.924,2.369c-0.476,0-0.96-0.03-1.439-0.091l-0.454-0.059	c-2.989-0.383-5.665-1.914-7.519-4.311c-1.855-2.401-2.663-5.379-2.275-8.386l2.102-17.645c-0.402,0.048-0.81,0.072-1.221,0.072	c-0.433,0-0.871-0.028-1.303-0.083c-2.711-0.347-5.12-1.728-6.787-3.887c-1.667-2.155-2.395-4.834-2.049-7.539l1.494-11.651	c0.171-1.339,0.601-2.614,1.278-3.79c0.291-0.548,1.301-2.436,4.969-9.283c1.553-3.53,4.445-6.316,7.978-7.756	c-0.063-0.232-0.121-0.46-0.175-0.682c-0.497-2.054-0.77-4.628-0.77-7.255c0-6.508,5.106-12.08,11.879-12.96l0.218-0.023	c0.492-0.043,1.084-0.086,1.733-0.086c0.596,0,1.156,0.036,1.712,0.109c5.98,0.778,10.665,5.222,11.673,10.736	c0.499-0.122,1.016-0.187,1.54-0.187c1.129,0,2.244,0.297,3.226,0.856c3.117,1.794,4.192,5.773,2.412,8.879l-0.63,1.095	c0.324,1.514,0.114,3.147-0.714,4.593c-0.02,0.033-0.039,0.067-0.06,0.101c0.569,0.805,1.06,1.669,1.461,2.58	c0,0,4.655,8.697,4.984,9.311c0.658,1.139,1.094,2.438,1.263,3.769l1.492,11.644c0.347,2.706-0.381,5.385-2.049,7.542	c-1.666,2.157-4.075,3.538-6.782,3.885c-0.443,0.055-0.875,0.081-1.3,0.081c-0.001,0-0.001,0-0.001,0	c-0.413,0-0.822-0.023-1.227-0.072l2.109,17.708c0.381,2.95-0.428,5.923-2.282,8.322c-1.854,2.397-4.529,3.929-7.533,4.314	l-0.427,0.055C58.513,97.686,58.02,97.718,57.532,97.718z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <line x1="63.702" x2="60.806" y1="25.15" y2="23.485" fill="none" stroke="#40396e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" data-sentry-element="line" data-sentry-source-file="NinjaIcon.tsx"></line>
    <line x1="65.046" x2="57.425" y1="19.463" y2="32.717" fill="none" stroke="#40396e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" data-sentry-element="line" data-sentry-source-file="NinjaIcon.tsx"></line>
    <path fill="#707cc0" d="M59.713,29.038l-5.833-1.605h-8.167l-5.449,1.539c-3.689,0.922-6.279,4.237-6.279,8.041v1.817 l6.216,2.072v15.541h19.685V40.901l6.216-2.072v-1.724C66.103,33.259,63.457,29.918,59.713,29.038z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <rect width="7.032" height="5.18" x="46.484" y="22.252" fill="#707cc0" data-sentry-element="rect" data-sentry-source-file="NinjaIcon.tsx"></rect>
    <path fill="#707cc0" d="M51.035,8.858L51.035,8.858l0.001,0c-0.002,0-0.003,0-0.005,0C50.694,8.814,50.351,8.783,50,8.783 s-0.694,0.03-1.032,0.074c-0.002,0-0.003,0-0.005,0h0.001v0c-3.514,0.458-6.217,3.196-6.217,6.515c0,1.927,0.191,4.085,0.586,5.722 c0.377,1.554,0.918,3.05,2.207,4.116l0.965,0.719c0.888,0.678,1.956-1.03,3.044-0.956c0.005,0,0.01,0.001,0.015,0.001 c0.145,0.009,0.289,0.015,0.435,0.014c0.145,0.001,0.29-0.005,0.435-0.014c0.005,0,0.01-0.001,0.015-0.001 c1.089-0.073,2.157,1.634,3.044,0.956l0.965-0.719c1.289-1.066,1.83-2.562,2.207-4.116c0.396-1.637,0.586-3.795,0.586-5.722 C57.252,12.054,54.549,9.316,51.035,8.858z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <path fill="#707cc0" d="M59.038,91.122L59.038,91.122c-2.654,0.34-5.08-1.535-5.421-4.189l-4.092-31.928l9.61-1.232 l4.092,31.928C63.567,88.355,61.692,90.782,59.038,91.122z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <path fill="#707cc0" d="M40.892,91.122l0.424,0.054c2.654,0.34,5.08-1.535,5.421-4.189L52.7,56.181l-11.905-2.407 l-4.092,31.928C36.363,88.355,38.239,90.782,40.892,91.122z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <rect width="19.685" height="5.18" x="40.202" y="51" fill="#f4665c" data-sentry-element="rect" data-sentry-source-file="NinjaIcon.tsx"></rect>
    <path fill="#707cc0" d="M30.657,60.768L30.657,60.768c-2.033-0.261-3.47-2.12-3.209-4.153l1.493-11.65 c0.261-2.033,2.12-3.47,4.153-3.209h0c2.033,0.261,3.47,2.12,3.209,4.153l-1.493,11.65C34.549,59.592,32.69,61.029,30.657,60.768z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <path fill="#707cc0" d="M31.068,48.861L31.068,48.861c-1.925-1.031-2.65-3.427-1.619-5.352L35.26,32.66 c1.031-1.925,3.427-2.65,5.352-1.619l0,0c1.925,1.031,2.65,3.427,1.619,5.352L36.42,47.242 C35.389,49.167,32.993,49.892,31.068,48.861z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <path fill="#707cc0" d="M69.343,60.768L69.343,60.768c2.033-0.261,3.47-2.12,3.209-4.153l-1.493-11.65 c-0.261-2.033-2.12-3.47-4.153-3.209h0c-2.033,0.261-3.47,2.12-3.209,4.153l1.493,11.65C65.451,59.592,67.31,61.029,69.343,60.768z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <path fill="#707cc0" d="M68.932,48.861L68.932,48.861c1.925-1.031,2.65-3.427,1.619-5.352L64.74,32.66 c-1.031-1.925-3.427-2.65-5.352-1.619l0,0c-1.925,1.031-2.65,3.427-1.619,5.352l5.811,10.849 C64.611,49.167,67.007,49.892,68.932,48.861z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <path fill="none" stroke="#40396e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" d="M72.034,56.615l-1.493-11.65c-0.067-0.523-0.243-1.005-0.498-1.43c-0.005-0.009-5.168-9.655-5.168-9.655 c-0.977-2.388-3.037-4.254-5.658-4.909l-5.746-1.539v-1.519l0.943-0.702c1.289-1.066,1.83-2.562,2.207-4.116 c0.396-1.637,0.586-3.795,0.586-5.722c0-3.319-2.704-6.057-6.221-6.515c-0.668-0.087-1.308-0.056-1.978,0.001 c-3.514,0.457-6.217,3.196-6.217,6.515c0,1.927,0.191,4.085,0.586,5.722c0.377,1.554,0.918,3.05,2.207,4.116l0.943,0.702v1.519 l-5.746,1.539c-2.621,0.655-4.681,2.521-5.658,4.909c0,0-5.157,9.628-5.168,9.655c-0.255,0.425-0.431,0.907-0.498,1.43 l-1.493,11.65c-0.261,2.033,1.176,3.892,3.209,4.153c2.033,0.261,3.892-1.176,4.153-3.209l1.254-9.787 c0.128-0.167,4.137-6.87,4.137-6.87v15.432l-3.498,29.369c-0.34,2.654,1.535,5.08,4.189,5.42l0.424,0.054 c2.654,0.34,5.08-1.535,5.42-4.189L50,65.568l2.745,21.42c0.34,2.654,2.767,4.529,5.42,4.189l0.424-0.054 c2.654-0.34,4.529-2.767,4.189-5.42L59.28,56.333V40.901c0,0,4.009,6.703,4.137,6.87l1.254,9.787 c0.261,2.033,2.12,3.47,4.153,3.209C70.858,60.507,72.295,58.648,72.034,56.615z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
    <path fill="#ffc7a3" d="M46.318,19.954h7.364c0.182-0.885,0.315-1.96,0.376-3.108h-8.116 C46.003,17.993,46.136,19.068,46.318,19.954z" data-sentry-element="path" data-sentry-source-file="NinjaIcon.tsx"></path>
  </svg>;
export default NinjaIcon;