'use client';

import { Flex, Text, useTheme } from '@chakra-ui/react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { CHAIN_MAIN } from '~/constants';
import { chainConfig } from '~/lib/global/chain';
export default function WelcomeHome() {
  useEffect(() => {
    AOS.init({
      once: false,
      mirror: true
    });
  }, []);
  const theme = useTheme();
  const {
    herosubtext1,
    herosubtext2,
    dexnamecolor1,
    dexnamecolor2
  } = chainConfig.text;
  return <Flex w="100%" mt="0rem" my="12px" direction="column" justify="center" align="center" data-sentry-element="Flex" data-sentry-component="WelcomeHome" data-sentry-source-file="WelcomeHome.tsx">
      <Flex w="100%" align="center" direction="column" justify="center" data-sentry-element="Flex" data-sentry-source-file="WelcomeHome.tsx">
        <motion.div initial={{
        opacity: 0
      }} whileInView={{
        opacity: 1
      }} transition={{
        ease: 'easeOut',
        duration: 1,
        delay: 0.5
      }} viewport={{
        once: true
      }} data-sentry-element="unknown" data-sentry-source-file="WelcomeHome.tsx">
          <Text fontSize={{
          base: '1.8rem',
          xs: '2.2rem',
          lg: '2.5rem'
        }} data-sentry-element="Text" data-sentry-source-file="WelcomeHome.tsx">
            <>
              {dexnamecolor1}
              <span style={{
              color: theme.colors.primary,
              textShadow: '0.5px 0 2px rgba(0, 0, 0, 1) '
            }}>
                {dexnamecolor2}
              </span>
              .
            </>
          </Text>
        </motion.div>
      </Flex>
      <motion.div initial={{
      opacity: 0
    }} whileInView={{
      opacity: 1,
      transition: {
        ease: 'easeIn',
        duration: 1.5,
        delay: 1
      }
    }} viewport={{
      once: true
    }} data-sentry-element="unknown" data-sentry-source-file="WelcomeHome.tsx">
        {herosubtext1 && herosubtext2 && <Flex>
            <Text fontSize={{
          base: '2rem',
          xs: '2.3rem',
          lg: '3rem'
        }} color="primary" textShadow="0.5px 0 2px rgba(0, 0, 0, 1)">
              {herosubtext1}&nbsp;
            </Text>

            <Text fontSize={{
          base: '2rem',
          xs: '2.3rem',
          lg: '3rem'
        }}>
              {herosubtext2}
              <span style={{
            color: theme.colors.primary
          }}>.</span>
            </Text>
          </Flex>}
      </motion.div>
    </Flex>;
}