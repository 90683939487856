import React from 'react';
const InterfaceIcon = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="InterfaceIcon" data-sentry-source-file="InterfaceIcon.tsx">
    <path d="M87.1,9H58.8c-2.7,0-5.2,1.2-6.8,3.2C50.4,10.2,47.9,9,45.2,9H16.9C12,9,8,13,8,17.9v28.3c0,4.9,4,8.9,8.9,8.9	h0.6c-1.8,2.2-3.4,5.7-3.4,11.3c0,5,2,9.9,4.6,13.4c-0.4,1-0.6,2.1-0.6,3.3v2c0,5.4,4.4,9.8,9.8,9.8H48c5.4,0,9.8-4.4,9.8-9.8v-2	c0-1.5-0.3-3-1-4.3c2.2-5.4,2.3-12.2,2.3-13.1c0-4.1-0.7-8.3-0.8-8.4c-0.1-0.8-0.3-1.6-0.6-2.3c0.3,0,0.7,0.1,1,0.1h28.3	c4.9,0,8.9-4,8.9-8.9V17.9C96,13,92,9,87.1,9z" opacity=".35" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <path fill="#f2f2f2" d="M85.1,7H56.8c-2.7,0-5.2,1.2-6.8,3.2C48.4,8.2,45.9,7,43.2,7H14.9C10,7,6,11,6,15.9v28.3c0,4.9,4,8.9,8.9,8.9	h0.6c-1.8,2.2-3.4,5.7-3.4,11.3c0,5,2,9.9,4.6,13.4c-0.4,1-0.6,2.1-0.6,3.3v2c0,5.4,4.4,9.8,9.8,9.8H46c5.4,0,9.8-4.4,9.8-9.8v-2	c0-1.5-0.3-3-1-4.3c2.2-5.4,2.3-12.2,2.3-13.1c0-4.1-0.7-8.3-0.8-8.4c-0.1-0.8-0.3-1.6-0.6-2.3c0.3,0,0.7,0.1,1,0.1h28.3	c4.9,0,8.9-4,8.9-8.9V15.9C94,11,90,7,85.1,7z" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <path fill="#ffc7a3" d="M50.1,63.8c0-3.5-0.7-7.4-0.7-7.4c-0.7-4-4.7-5.3-6.7-2.7c-1.3-3.3-4.7-3.3-6-1.3c-0.7-2.7-6-3.3-6.7,0	c0,0,0-2.8,0-4.5h-6c0,2.5,0,7.9,0,7.9s-6,0.7-6,8.7c0,6.7,5.3,13.4,8,13.4h-0.7c-1.8,0-3.3,1.5-3.3,3.3v2c0,1.8,1.5,3.3,3.3,3.3	h20.1c1.8,0,3.3-1.5,3.3-3.3v-2c0-1.8-1.5-3.3-3.3-3.3h-0.7C48.8,77.8,50.1,68.4,50.1,63.8z" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <path fill="#70bfff" d="M43.1,46.6H14.8c-1.3,0-2.4-1.1-2.4-2.4V15.9c0-1.3,1.1-2.4,2.4-2.4h28.3c1.3,0,2.4,1.1,2.4,2.4v28.3	C45.5,45.5,44.4,46.6,43.1,46.6z" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <path fill="#70bfff" stroke="#40396e" strokeMiterlimit="10" strokeWidth="3" d="M85.1,46.6H56.8c-1.3,0-2.4-1.1-2.4-2.4V15.9c0-1.3,1.1-2.4,2.4-2.4h28.3c1.3,0,2.4,1.1,2.4,2.4v28.3	C87.5,45.5,86.4,46.6,85.1,46.6z" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <rect width="24" height="24" x="17" y="18" fill="#d9eeff" data-sentry-element="rect" data-sentry-source-file="InterfaceIcon.tsx"></rect>
    <path fill="#70bfff" d="M25.2,37.2c-0.4,0-0.7-0.1-1-0.4L19.4,32c-0.6-0.6-0.6-1.5,0-2c0.6-0.6,1.5-0.6,2,0l3.8,3.8l11.5-11.5	c0.6-0.6,1.5-0.6,2,0c0.6,0.6,0.6,1.5,0,2L26.2,36.8C26,37.1,25.6,37.2,25.2,37.2z" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <path fill="#70bfff" d="M22.6,85.5h26.7v-6.7H22.6V85.5z" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <circle cx="44" cy="82" r="1.9" fill="#40396e" data-sentry-element="circle" data-sentry-source-file="InterfaceIcon.tsx"></circle>
    <polygon fill="#40396e" points="24,64.7 24,55.7 22,57.3" opacity=".35" data-sentry-element="polygon" data-sentry-source-file="InterfaceIcon.tsx"></polygon>
    <path fill="#ffc7a3" d="M26.6,52.8L26.6,52.8c-1.4,0-2.5-1.1-2.5-2.5V42c0-1.4,1.1-2.5,2.5-2.5l0,0c1.4,0,2.5,1.1,2.5,2.5v8.3	C29.1,51.7,28,52.8,26.6,52.8z" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <path fill="none" stroke="#40396e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" d="M46,77.8h-0.7c4,0,5.3-9.4,5.3-14c0-3.5-0.7-7.4-0.7-7.4c-0.7-4-4.7-5.3-6.7-2.7c-1.3-3.3-4.7-3.3-6-1.3	c-0.7-2.7-6-3.3-6.7,0c0,0,0-5.7,0-5.8h12.6c1.3,0,2.4-1.1,2.4-2.4V15.9c0-1.3-1.1-2.4-2.4-2.4H14.9c-1.3,0-2.4,1.1-2.4,2.4v28.3	c0,1.3,1.1,2.4,2.4,2.4h7.7c0,0,0,9.1,0,9.1s-4,0.7-4,8.7c0,6.7,5.3,13.4,8,13.4h-0.7c-1.8,0-3.3,1.5-3.3,3.3v2	c0,1.8,1.5,3.3,3.3,3.3H46c1.8,0,3.3-1.5,3.3-3.3v-2C49.3,79.3,47.8,77.8,46,77.8z" data-sentry-element="path" data-sentry-source-file="InterfaceIcon.tsx"></path>
    <rect width="24" height="24" x="59" y="18" fill="#d9eeff" data-sentry-element="rect" data-sentry-source-file="InterfaceIcon.tsx"></rect>
  </svg>;
export default InterfaceIcon;