const Osmo = () => <svg viewBox="0 0 31 34" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
  width: '100%',
  height: '100%'
}} data-sentry-element="svg" data-sentry-component="Osmo" data-sentry-source-file="Osmo.tsx">
    <path d="M30.6156 5.86803C30.2788 4.56675 29.196 3.26549 27.2471 1.81688C25.6831 0.662916 24.0229 0 22.6996 0C22.4349 0 22.1944 0.0245463 21.9537 0.0736554C21.3522 0.19642 20.8229 0.638361 20.486 1.30128C20.077 2.08696 19.9808 3.14272 20.2454 3.78109C20.3417 3.97751 20.462 4.22301 20.6063 4.44398C19.3311 5.22968 18.6093 5.45065 18.5131 5.47522C21.8335 6.60459 24.6005 8.96168 26.3328 12.0307L26.3569 11.7361C26.429 10.9259 26.6696 9.99284 27.0065 9.03531C27.3433 9.13351 27.6802 9.18265 28.0171 9.18265C28.9073 9.18265 29.6773 8.81433 30.1585 8.15141C30.6397 7.48848 30.8322 6.60459 30.6156 5.86803Z" fill="#5E12A0" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path d="M27.1509 8.3234C29.3405 8.93727 30.2306 7.21861 29.9419 6.0646C29.6292 4.91066 28.6186 3.73212 26.8381 2.40628C25.0576 1.08045 23.2531 0.540289 22.0981 0.785818C20.9432 1.03134 20.6063 2.82367 20.8951 3.51114C21.0153 3.78122 21.28 4.17403 21.6409 4.61598C21.1838 4.93515 20.7507 5.1807 20.3898 5.40167C22.6034 6.40834 24.5283 7.97974 25.9719 9.94394C26.1403 9.28101 26.381 8.69172 26.5975 8.17614C26.7659 8.20071 26.9584 8.24977 27.1509 8.3234Z" fill="url(#paint0_radial_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path d="M14.6153 32.3846C22.0435 32.3846 28.0653 26.2398 28.0653 18.6598C28.0653 11.0798 22.0435 4.93494 14.6153 4.93494C7.18707 4.93494 1.16528 11.0798 1.16528 18.6598C1.16528 26.2398 7.18707 32.3846 14.6153 32.3846Z" fill="url(#paint1_radial_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.6" d="M28.8355 4.93496C27.0069 2.97075 25.467 2.45514 23.6143 2.03776C22.1706 1.69402 22.5557 0.859236 24.3121 1.0311C23.4699 0.736472 22.6759 0.662817 22.0985 0.785573C20.9435 1.0311 20.6067 2.82344 20.8955 3.51091C21.0157 3.78098 21.2804 4.17384 21.6413 4.61579C20.9917 5.05774 20.4383 5.37691 19.9811 5.62246C20.1977 5.72066 20.4623 5.84335 20.7751 6.01527C21.5932 6.45722 22.4835 7.19377 22.4835 7.19377C21.136 6.01527 21.4247 5.47512 23.2774 4.14927C23.8549 3.73188 24.9136 3.78098 25.9001 4.29661C26.8866 4.81219 28.0415 6.11347 28.0415 6.11347L26.9347 8.27407C27.0069 8.29864 27.0791 8.32322 27.1512 8.34779C27.849 8.54419 28.4024 8.49505 28.8355 8.32322C29.3408 8.00404 30.6641 6.92374 28.8355 4.93496Z" fill="#A98698" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path d="M23.5896 3.21652C24.0708 3.41294 24.6964 3.75668 25.4664 4.27232C26.3807 4.8861 27.1747 5.5736 27.68 6.11375C26.8378 7.24311 26.2844 8.78993 25.9476 9.87024C26.1161 10.1158 26.3086 10.3613 26.4769 10.6069C26.6453 9.99301 26.9341 9.08454 27.3191 8.17615C27.4154 8.20072 27.5356 8.20072 27.6559 8.20072C27.9446 8.20072 28.2815 8.15158 28.5462 7.93061C28.7387 7.78326 28.9552 7.51323 28.9311 7.02214C28.9311 6.5557 28.5702 5.96641 27.8244 5.25442C27.295 4.73876 26.5732 4.17404 25.8514 3.65847C23.7821 2.25898 22.3385 1.86614 21.6889 2.52905C21.2558 2.971 21.3038 3.51116 21.4482 3.904C20.6783 4.41958 20.0287 4.7879 19.5956 5.03345C19.8843 5.13165 20.149 5.25442 20.4377 5.37711C21.2076 4.95973 22.3144 4.24775 23.5896 3.21652ZM28.1371 6.72753C28.2093 6.8503 28.2334 6.97308 28.2334 7.04671C28.2334 7.26768 28.1612 7.34131 28.1131 7.39046C28.0168 7.46409 27.8243 7.51323 27.6559 7.51323C27.8003 7.21862 27.9688 6.97308 28.1371 6.72753ZM22.1941 3.06921C22.2663 2.99556 22.4588 2.94645 22.7716 2.99556C22.531 3.19198 22.2904 3.36384 22.0497 3.53571C22.0257 3.36384 22.0497 3.19198 22.1941 3.06921Z" fill="#5E12A0" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path d="M14.6151 4.12512C6.74722 4.12512 0.371094 10.6316 0.371094 18.6602C0.371094 26.6889 6.74722 33.1953 14.6151 33.1953C22.483 33.1953 28.8591 26.6889 28.8591 18.6602C28.8591 10.6316 22.4589 4.12512 14.6151 4.12512ZM14.6151 32.3851C7.18029 32.3851 1.1651 26.247 1.1651 18.6602C1.1651 11.0735 7.18029 4.93539 14.6151 4.93539C22.0499 4.93539 28.0651 11.0735 28.0651 18.6602C28.0651 26.247 22.0259 32.3851 14.6151 32.3851Z" fill="#5E12A0" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.6" d="M14.6153 32.3846C22.0435 32.3846 28.0653 26.2398 28.0653 18.6598C28.0653 11.0798 22.0435 4.93494 14.6153 4.93494C7.18707 4.93494 1.16528 11.0798 1.16528 18.6598C1.16528 26.2398 7.18707 32.3846 14.6153 32.3846Z" fill="url(#paint2_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.6" d="M14.5188 30.7153C7.1802 29.5122 2.19959 22.4657 3.40261 14.9771C3.93198 11.6626 5.56807 8.83906 7.87796 6.80115C4.50941 8.78992 2.00711 12.2518 1.33341 16.4749C0.154421 23.9634 5.13499 31.01 12.4495 32.2131C16.5399 32.9005 20.4858 31.5992 23.3972 29.0457C20.8227 30.568 17.6948 31.2309 14.5188 30.7153Z" fill="#A98698" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.6" d="M16.7569 5.13145C13.3643 4.56672 10.068 5.35242 7.37323 7.09565C7.32509 7.14479 7.27702 7.19385 7.27702 7.19385C8.31156 6.5555 9.82744 5.99077 9.82744 5.99077C5.92957 8.29872 4.72656 10.9258 4.72656 10.9258C6.2424 7.93048 10.6936 5.81894 14.1824 5.6716C17.6712 5.52433 19.957 6.58007 22.7481 8.86344C25.5391 11.1714 27.2234 15.8855 27.0549 19.6175C26.9106 23.3494 24.9858 26.3694 24.9858 26.3694C26.3091 24.6262 27.1032 23.3494 27.6084 22.0481C27.7047 21.6553 27.8009 21.2625 27.8491 20.845C29.0521 13.3811 24.0954 6.33452 16.7569 5.13145Z" fill="url(#paint3_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path d="M27.3914 18.5374C27.3914 25.7312 21.6649 31.5748 14.6152 31.5748C7.56537 31.5748 1.81482 25.7312 1.81482 18.5374H27.3914Z" fill="url(#paint4_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <g opacity="0.6" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.6" d="M26.3811 18.5374C26.3811 25.5594 20.9434 31.3047 14.1101 31.5748C14.2786 31.5748 14.447 31.5748 14.6154 31.5748C21.6652 31.5748 27.3917 25.7312 27.3917 18.5374H26.3811Z" fill="url(#paint5_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <g opacity="0.7" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.7" d="M3.61939 18.5374H1.81482C1.81482 25.7312 7.54134 31.5748 14.5911 31.5748C14.9039 31.5748 15.1926 31.5748 15.4813 31.5502C8.86465 31.0592 3.61939 25.4121 3.61939 18.5374Z" fill="url(#paint6_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <path d="M27.3674 18.6597C27.3674 17.162 24.8169 16.3027 21.4244 16.0081C18.9702 15.8116 16.4919 16.0571 13.6527 16.941C11.1985 17.6776 8.9849 17.5548 7.37286 17.3584C3.78778 16.941 1.81482 16.8919 1.81482 18.6597C1.81482 21.2132 6.91568 24.405 14.567 23.3002C18.4408 22.7354 20.4379 21.5815 22.7237 20.7958C25.202 19.961 27.3674 19.9855 27.3674 18.6597Z" fill="url(#paint7_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path d="M18.8018 12.4728C19.9978 12.4728 20.9673 11.4835 20.9673 10.2631C20.9673 9.04269 19.9978 8.05334 18.8018 8.05334C17.6059 8.05334 16.6364 9.04269 16.6364 10.2631C16.6364 11.4835 17.6059 12.4728 18.8018 12.4728Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path d="M22.3379 14.1178C22.8429 14.1178 23.2522 13.7001 23.2522 13.1848C23.2522 12.6695 22.8429 12.2518 22.3379 12.2518C21.833 12.2518 21.4236 12.6695 21.4236 13.1848C21.4236 13.7001 21.833 14.1178 22.3379 14.1178Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <g opacity="0.6" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.6" d="M25.611 8.49484C25.587 8.49484 25.5629 8.49484 25.5629 8.49484C25.4185 8.47027 25.3223 8.323 25.3463 8.15109C25.5629 7.02173 26.4772 5.94134 26.5253 5.89228C26.6215 5.76951 26.814 5.76951 26.9103 5.86771C27.0306 5.96591 27.0306 6.16232 26.9343 6.2606C26.9103 6.28509 26.0681 7.29176 25.8757 8.27386C25.8516 8.42121 25.7313 8.49484 25.611 8.49484Z" fill="url(#paint8_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <path opacity="0.2" d="M13.7268 28.8981C14.0988 28.8981 14.4005 28.5903 14.4005 28.2107C14.4005 27.831 14.0988 27.5232 13.7268 27.5232C13.3547 27.5232 13.0531 27.831 13.0531 28.2107C13.0531 28.5903 13.3547 28.8981 13.7268 28.8981Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <g opacity="0.4" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.6" d="M13.3396 28.6536C13.075 28.3835 13.075 27.9415 13.3396 27.6715C13.3878 27.6223 13.4359 27.5978 13.484 27.5487C13.3878 27.5733 13.3156 27.6223 13.2434 27.696C12.9788 27.9661 12.9788 28.408 13.2434 28.6781C13.46 28.8991 13.7968 28.9482 14.0615 28.8009C13.8209 28.8991 13.5322 28.85 13.3396 28.6536Z" fill="url(#paint9_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <path opacity="0.3" d="M13.965 28.0141C14.0448 28.0141 14.1095 27.9482 14.1095 27.8668C14.1095 27.7855 14.0448 27.7195 13.965 27.7195C13.8853 27.7195 13.8207 27.7855 13.8207 27.8668C13.8207 27.9482 13.8853 28.0141 13.965 28.0141Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.2" d="M17.2393 28.5548C17.6114 28.5548 17.913 28.2471 17.913 27.8674C17.913 27.4877 17.6114 27.1799 17.2393 27.1799C16.8673 27.1799 16.5657 27.4877 16.5657 27.8674C16.5657 28.2471 16.8673 28.5548 17.2393 28.5548Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <g opacity="0.4" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.6" d="M16.8534 28.3097C16.5888 28.0396 16.5888 27.5976 16.8534 27.3276C16.9016 27.2785 16.9497 27.254 16.9978 27.2048C16.9016 27.2294 16.8294 27.2785 16.7572 27.3522C16.4925 27.6222 16.4925 28.0642 16.7572 28.3343C16.9738 28.5553 17.3106 28.6043 17.5752 28.457C17.3347 28.5552 17.0459 28.5061 16.8534 28.3097Z" fill="url(#paint10_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <path opacity="0.3" d="M17.4788 27.6708C17.5586 27.6708 17.6232 27.6048 17.6232 27.5234C17.6232 27.4421 17.5586 27.3761 17.4788 27.3761C17.3991 27.3761 17.3345 27.4421 17.3345 27.5234C17.3345 27.6048 17.3991 27.6708 17.4788 27.6708Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.2" d="M15.5057 30.1504C15.7848 30.1504 16.011 29.9196 16.011 29.6348C16.011 29.35 15.7848 29.1191 15.5057 29.1191C15.2266 29.1191 15.0004 29.35 15.0004 29.6348C15.0004 29.9196 15.2266 30.1504 15.5057 30.1504Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <g opacity="0.4" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.6" d="M15.2169 29.9549C15.0244 29.7585 15.0244 29.4148 15.2169 29.2184C15.241 29.1938 15.2891 29.1692 15.3131 29.1447C15.241 29.1692 15.1928 29.2184 15.1447 29.2674C14.9522 29.4638 14.9522 29.8076 15.1447 30.004C15.3131 30.1759 15.5537 30.2005 15.7703 30.0777C15.5778 30.1513 15.3613 30.1023 15.2169 29.9549Z" fill="url(#paint11_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <path opacity="0.3" d="M15.6733 29.4631C15.7264 29.4631 15.7695 29.4191 15.7695 29.3649C15.7695 29.3107 15.7264 29.2667 15.6733 29.2667C15.6201 29.2667 15.577 29.3107 15.577 29.3649C15.577 29.4191 15.6201 29.4631 15.6733 29.4631Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.2" d="M18.6096 29.7821C18.8887 29.7821 19.1149 29.5513 19.1149 29.2666C19.1149 28.9818 18.8887 28.751 18.6096 28.751C18.3306 28.751 18.1044 28.9818 18.1044 29.2666C18.1044 29.5513 18.3306 29.7821 18.6096 29.7821Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <g opacity="0.4" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.6" d="M18.3209 29.5868C18.1284 29.3903 18.1284 29.0465 18.3209 28.8501C18.345 28.8255 18.3931 28.8011 18.4171 28.7765C18.345 28.8011 18.2968 28.8501 18.2487 28.8993C18.0562 29.0957 18.0562 29.4394 18.2487 29.6358C18.4171 29.8077 18.6577 29.8322 18.8743 29.7094C18.6818 29.7832 18.4653 29.734 18.3209 29.5868Z" fill="url(#paint12_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <path opacity="0.3" d="M18.7782 29.095C18.8314 29.095 18.8745 29.051 18.8745 28.9968C18.8745 28.9426 18.8314 28.8986 18.7782 28.8986C18.7251 28.8986 18.682 28.9426 18.682 28.9968C18.682 29.051 18.7251 29.095 18.7782 29.095Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.2" d="M10.8614 28.6527C11.4727 28.6527 11.9682 28.1469 11.9682 27.5232C11.9682 26.8995 11.4727 26.3938 10.8614 26.3938C10.2501 26.3938 9.75464 26.8995 9.75464 27.5232C9.75464 28.1469 10.2501 28.6527 10.8614 28.6527Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <g opacity="0.4" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.6" d="M10.2365 28.2357C9.80342 27.7937 9.80342 27.0817 10.2365 26.6397C10.3087 26.5661 10.3809 26.517 10.4771 26.4679C10.3328 26.517 10.2125 26.6152 10.0921 26.7134C9.65907 27.1554 9.65907 27.8674 10.0921 28.3093C10.4531 28.6776 11.0065 28.7513 11.4396 28.4812C11.0305 28.6531 10.5493 28.5794 10.2365 28.2357Z" fill="url(#paint13_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <path opacity="0.3" d="M11.2468 27.1795C11.3797 27.1795 11.4874 27.0696 11.4874 26.934C11.4874 26.7984 11.3797 26.6885 11.2468 26.6885C11.114 26.6885 11.0062 26.7984 11.0062 26.934C11.0062 27.0696 11.114 27.1795 11.2468 27.1795Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <path opacity="0.2" d="M20.751 25.8787C21.3622 25.8787 21.8578 25.373 21.8578 24.7492C21.8578 24.1255 21.3622 23.6199 20.751 23.6199C20.1397 23.6199 19.6442 24.1255 19.6442 24.7492C19.6442 25.373 20.1397 25.8787 20.751 25.8787Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <g opacity="0.4" data-sentry-element="g" data-sentry-source-file="Osmo.tsx">
      <path opacity="0.6" d="M20.1006 25.4612C19.6676 25.0192 19.6676 24.3072 20.1006 23.8653C20.1728 23.7916 20.245 23.7425 20.3413 23.6934C20.1969 23.7425 20.0766 23.8407 19.9563 23.9389C19.5232 24.3809 19.5232 25.0929 19.9563 25.5349C20.3172 25.9031 20.8706 25.9768 21.3037 25.7067C20.8947 25.8785 20.4375 25.8049 20.1006 25.4612Z" fill="url(#paint14_linear_1514_12718)" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    </g>
    <path opacity="0.3" d="M21.1352 24.405C21.2681 24.405 21.3757 24.2951 21.3757 24.1595C21.3757 24.0239 21.2681 23.9139 21.1352 23.9139C21.0023 23.9139 20.8945 24.0239 20.8945 24.1595C20.8945 24.2951 21.0023 24.405 21.1352 24.405Z" fill="white" data-sentry-element="path" data-sentry-source-file="Osmo.tsx" />
    <defs data-sentry-element="defs" data-sentry-source-file="Osmo.tsx">
      <radialGradient id="paint0_radial_1514_12718" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29.5031 4.51777) scale(10.7539 10.9736)" data-sentry-element="radialGradient" data-sentry-source-file="Osmo.tsx">
        <stop stopColor="#FFEAFF" stopOpacity="0.6" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.6807" stopColor="#A087C9" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="1" stopColor="#10002F" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </radialGradient>
      <radialGradient id="paint1_radial_1514_12718" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.5203 9.19757) scale(26.3108 26.8484)" data-sentry-element="radialGradient" data-sentry-source-file="Osmo.tsx">
        <stop stopColor="#FFEAFF" stopOpacity="0.6" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.6807" stopColor="#A087C9" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="1" stopColor="#10002F" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </radialGradient>
      <linearGradient id="paint2_linear_1514_12718" x1="12.4578" y1="32.1899" x2="16.9327" y2="5.13547" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop stopColor="#81FFFF" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.6202" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint3_linear_1514_12718" x1="24.0943" y1="9.33891" x2="12.7671" y2="19.5238" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint4_linear_1514_12718" x1="1.82564" y1="25.0485" x2="27.3853" y2="25.0485" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop stopColor="#0002E9" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.9952" stopColor="#FF00C7" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint5_linear_1514_12718" x1="28.5954" y1="19.6814" x2="14.4182" y2="32.8113" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint6_linear_1514_12718" x1="1.82564" y1="25.0485" x2="15.4999" y2="25.0485" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop stopColor="#000292" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.9952" stopColor="#7D00C7" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint7_linear_1514_12718" x1="1.8428" y1="19.7428" x2="27.3681" y2="19.7428" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop stopColor="#000292" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.9952" stopColor="#BE00C7" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint8_linear_1514_12718" x1="26.9494" y1="5.77268" x2="25.0605" y2="8.78062" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint9_linear_1514_12718" x1="13.0443" y1="28.2327" x2="14.0622" y2="28.2327" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint10_linear_1514_12718" x1="16.5555" y1="27.8841" x2="17.5735" y2="27.8841" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint11_linear_1514_12718" x1="14.9972" y1="29.6416" x2="15.7615" y2="29.6416" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint12_linear_1514_12718" x1="18.0908" y1="29.2725" x2="18.855" y2="29.2725" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint13_linear_1514_12718" x1="9.75633" y1="27.5524" x2="11.4187" y2="27.5524" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
      <linearGradient id="paint14_linear_1514_12718" x1="19.6296" y1="24.7771" x2="21.2921" y2="24.7771" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Osmo.tsx">
        <stop offset="0.2888" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
        <stop offset="0.7796" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Osmo.tsx" />
      </linearGradient>
    </defs>
  </svg>;
export default Osmo;