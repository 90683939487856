import React from 'react';
const Dashboard = () => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-component="Dashboard" data-sentry-source-file="Dashboard.tsx">
    <path d="M64.502,100c-2.327,0-4.49-1.255-5.645-3.274L52,84.727l-6.856,11.998 C43.989,98.745,41.826,100,39.499,100c-1.125,0-2.238-0.295-3.217-0.853c-1.514-0.865-2.596-2.263-3.052-3.938 c-0.457-1.676-0.234-3.429,0.628-4.937L40.3,79H13.5C9.916,79,7,76.084,7,72.5c0-2.699,1.653-5.019,4-6V29 c-2.347-0.981-4-1.801-4-4.5v-7c0-3.584,2.916-6.5,6.5-6.5h77c3.584,0,6.5,2.916,6.5,6.5v7c0,2.699-1.653,5.019-4,6v36 c2.347,0.981,4,3.301,4,6c0,3.584-2.916,6.5-6.5,6.5h-26.8l6.443,11.275c0.861,1.506,1.084,3.259,0.627,4.935 c-0.457,1.675-1.538,3.072-3.045,3.934C66.74,99.705,65.627,100,64.502,100z" opacity=".35" data-sentry-element="path" data-sentry-source-file="Dashboard.tsx"></path>
    <path fill="#f2f2f2" d="M62.502,98c-2.327,0-4.49-1.255-5.645-3.274L50,82.727l-6.856,11.998 C41.989,96.745,39.826,98,37.499,98c-1.125,0-2.238-0.295-3.217-0.853c-1.514-0.865-2.596-2.263-3.052-3.938 c-0.457-1.676-0.234-3.429,0.628-4.937L38.3,77H11.5C7.916,77,5,74.084,5,70.5c0-2.699,1.653-5.019,4-6v-36 c-2.347-0.981-4-3.301-4-6v-7C5,11.916,7.916,9,11.5,9h77c3.584,0,6.5,2.916,6.5,6.5v7c0,2.699-1.653,5.019-4,6v36 c2.347,0.981,4,3.301,4,6c0,3.584-2.916,6.5-6.5,6.5h-26.8l6.443,11.275c0.861,1.506,1.084,3.259,0.627,4.935 c-0.457,1.675-1.538,3.072-3.045,3.934C64.74,97.705,63.627,98,62.502,98z" data-sentry-element="path" data-sentry-source-file="Dashboard.tsx"></path>
    <polygon fill="#d9eeff" points="88.5,15.5 84.5,15.5 15.5,15.5 11.5,15.5 11.5,23.448 15.5,23.448 15.5,70 84.5,70 84.5,23.448 88.5,23.448" data-sentry-element="polygon" data-sentry-source-file="Dashboard.tsx"></polygon>
    <polygon fill="#f9b84f" points="55,59 45,59 45,36 55,36" data-sentry-element="polygon" data-sentry-source-file="Dashboard.tsx"></polygon>
    <polygon fill="#f4665c" points="39,59 29,59 29,42 39,42" data-sentry-element="polygon" data-sentry-source-file="Dashboard.tsx"></polygon>
    <polygon fill="#70bfff" points="71,59 61,59 61,31 71,31" data-sentry-element="polygon" data-sentry-source-file="Dashboard.tsx"></polygon>
    <rect width="77" height="4.5" x="11.5" y="16.5" fill="#707cc0" data-sentry-element="rect" data-sentry-source-file="Dashboard.tsx"></rect>
    <polygon fill="none" stroke="#40396e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" points="88.5,15.5 84.5,15.5 15.5,15.5 11.5,15.5 11.5,22.5 15.5,22.5 15.5,70.5 84.5,70.5 84.5,22.5 88.5,22.5" data-sentry-element="polygon" data-sentry-source-file="Dashboard.tsx"></polygon>
    <line x1="11.5" x2="88.5" y1="70.5" y2="70.5" fill="none" stroke="#40396e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" data-sentry-element="line" data-sentry-source-file="Dashboard.tsx"></line>
    <line x1="49.5" x2="37.5" y1="70.5" y2="91.5" fill="none" stroke="#40396e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" data-sentry-element="line" data-sentry-source-file="Dashboard.tsx"></line>
    <line x1="50.5" x2="62.5" y1="70.5" y2="91.5" fill="none" stroke="#40396e" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3" data-sentry-element="line" data-sentry-source-file="Dashboard.tsx"></line>
  </svg>;
export default Dashboard;